import styled from "styled-components";
export const StyledModal = styled.div`
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: auto;
  margin-top: 20%;
`;
export const StyledMain = styled.div`
  width: 100%;
  height: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  //   height: 835px;
  // }
  // @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  //   height: 835px;
  // }
  // @media only screen and (min-width: 901px) and (max-width: 1080px) {
  //   height: 835px;
  // }
  // @media only screen and (min-width: 811px) and (max-width: 900px) {
  //   height: 835px;
  // }
  // @media only screen and (min-width: 641px) and (max-width: 810px) {
  //   height: 835px;
  // }
  // @media only screen and (min-width: 320px) and (max-width: 640px) {
  //   height: 835px;
  // }
`;
export const CloseButton = styled.button`
  cursor: pointer;
  position: relative;
  top: 2%;
  left: 45%;
  border-radius: 50%;
  border: none;
  width: 47px;
  height: 47px;
  flex-shrink: 0;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.16));
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 31.049px;
    height: 31.049px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 31.049px;
    height: 31.049px;
  }
`;
export const StyledContainer = styled.div`
  width: 90%;
  height: auto;
  border: 1px solid #f5f5f5;
  background: #f7fafb;
  box-shadow: -6px 9px 29px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  //   height: 908px;
  // }
  // @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  //   height: 757px;
  // }
  // @media only screen and (min-width: 811px) and (max-width: 1080px) {
  //   height: 643px;
  // }
  // @media only screen and (min-width: 641px) and (max-width: 810px) {
  //   height: 624px;
  // }
  // @media only screen and (min-width: 320px) and (max-width: 640px) {
  //   height: 794px;
  // }
`;
export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
`;
export const StyledDiv = styled.div`
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 18%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 20%;
  }
  @media only screen and (min-width: 845px) and (max-width: 1080px) {
    width: 20%;
  }
  @media only screen and (min-width: 811px) and (max-width: 844px) {
    width: 23%;
  }
  @media only screen and (min-width: 676px) and (max-width: 810px) {
    width: 25%;
  }
  @media only screen and (min-width: 641px) and (max-width: 675px) {
    width: 30%;
  }
  @media only screen and (min-width: 480px) and (max-width: 640px) {
    width: 35%;
  }
  @media only screen and (min-width: 401px) and (max-width: 481px) {
    width: 40%;
  }
  @media only screen and (min-width: 320px) and (max-width: 400px) {
    width: 50%;
  }
`;
export const StyledLine = styled.div`
  width: 30px;
  height: 1.5px;
  flex-shrink: 0;
  background: #ee991c;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 24.643px;
    height: 1.232px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 20.666px;
    height: 1.033px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 16.042px;
    height: 0.802px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 16.042px;
    height: 0.802px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 16.042px;
    height: 0.802px;
  }
`;
export const StyledP = styled.p`
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.5%; /* 25.04px */
  letter-spacing: 2.16px;
  text-transform: uppercase;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledH1 = styled.h1`
  width: 60%;
  color: #03375e;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%; /* 39.04px */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 70%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    width: 80%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 14px;
    width: 80%;
  }
`;
export const StyledContent = styled.div`
  width: 80%;
  display: inline-flex;
  height: 500px;
  justify-content: flex-start;
  align-items: center;
  gap: 90px;
  flex-shrink: 0;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 85%;
    height: 438px;
    gap: 48px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 90%;
    height: auto;
    gap: 0px;
    margin-top: 4%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 90%;
    height: auto;
    gap: 0px;
    margin-top: 2%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 95%;
    height: 300px;
    gap: 0px;
    margin-top: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 95%;
    height: 480px;
    gap: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
  }
`;
export const StyledColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 30px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 25px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    gap: 18px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    gap: 10px;
  }
  // @media only screen and (min-width: 320px) and (max-width: 640px) {
  //   flex-direction: row;
  // }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 12px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    gap: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    gap: 10px;
  }
`;
export const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 26.286px;
    height: 26.286px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 22.044px;
    height: 22.044px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 17.111px;
    height: 17.111px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 17.111px;
    height: 17.111px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 17.111px;
    height: 17.111px;
  }
`;
export const StyledH3 = styled.h3`
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0.21px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    letter-spacing: 0.18px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 131%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 95%;
  }
`;
export const StyledList = styled.li`
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    letter-spacing: 0.21px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    letter-spacing: 0.18px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    letter-spacing: 0.18px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 131%;
  }
`;
export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-left: 15%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    gap: 11px;
    margin-left: 15%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 6px;
    margin-left: 10%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 4px;
    margin-left: 12%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    gap: 6px;
    margin-left: 15%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    gap: 6px;
    margin-left: 10%;
    // padding-inline-start: 20px;
  }
`;
export const StyledButton = styled.button`
  cursor: pointer;
  width: 228px;
  height: 50.924px;
  flex-shrink: 0;
  border-radius: 53px;
  background: #ee991c;
  border: none;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  &: hover {
    color: white;
    background: #03375e;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 205px;
    height: 44px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 193px;
    height: 38px;
    font-size: 14px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 193px;
    height: 38px;
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 193px;
    height: 38px;
    font-size: 14px;
  }
`;
export const StyledP1 = styled.div`
  width: 60%;
  color: #03375e;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
  margin-bottom: 3%;
  & span {
    color: #03375e;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.24px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    & span {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 12px;
    width: 80%;
    & span {
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    width: 80%;
    & span {
      font-size: 12px;
    }
  }
`;
