import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';


export const ContainerHeader= styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  
  @media only screen and (min-width:320px)and (max-width:480px){
 padding-left:10px;
 padding-right:10px;  
}
@media only screen and (min-width:481px)and (max-width:767px){
  padding-left:20px;
  padding-right:20px;  
}
@media only screen and (min-width:768px)and (max-width:1023px){
 /* padding-left:20px;
 padding-right:20px;  */
}

`;

export const Container1 = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1550px;
  margin-right: auto;
  margin-left: auto;
  padding-right:40px;
  padding-left:90px;
  @media only screen and (min-width: 992px) and (max-width:1079px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  
  @media only screen and (min-width: 1080px) and (max-width:1116px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (min-width: 961px) and (max-width:991px) {
    padding-right: 15px;
    padding-left: 30px;
  }
  @media screen and (min-width: 810px) and (max-width:960px) {
    padding-right: 30px;
    padding-left: 70px;
  }
  @media screen and (max-width: 809px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  `;
export const TextOverlay = styled.div`
  position: absolute;
  top: 490px;
  font-family:'Montserrat' !important;
  left: 10px;
  font-size:28px;
  line-height: 42px;
  font-weight:700;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: inline;
  @media only screen and (min-width:320px)and (max-width:359px){
    font-size:18px;
    top: 230px; 
    left:3px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:23.72px;

}
@media only screen and (min-width:360px)and (max-width:366px){
    font-size:18px;
    top: 230px; 
    left:6px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:23.72px;

}
  @media only screen and (min-width:367px)and (max-width:388px){
    font-size:18px;
    top: 230px; 
    left:24px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:23.72px;

}
@media only screen and (min-width:389px)and (max-width:440px){
    font-size:18px;
    top: 230px; 
    left:22px;
    max-width:402px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:23.72px;

}
@media only screen and (min-width:441px)and (max-width:480px){
    font-size:18px;
    top: 230px; 
    left:22px;
    max-width:402px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:23.72px;

}
@media only screen and (min-width:481px) and (max-width:639px){
    font-size:26px;
    top: 210px; 
    left:14px;
    width:100%;  
    text-align: center;
    font-family:'Montserrat' !important;
    line-height:32.72px;
}
@media only screen and (min-width:640px) and (max-width:809px){
  font-size:32px;
    top: 352px;
    width:100%; 
    line-height:45.36px; 
    text-align: center;
    left:25px;
    font-family:'Montserrat' !important;
    line-height:37.72px;
  }
@media only screen and (min-width:810px) and (max-width:866px){
  font-size:20px;
  top:390px;
  font-family:'Montserrat' !important;
  line-height:28.72px;
}
@media only screen and (min-width:867px) and (max-width:936px){
  font-size:20px;
  top:360px;
  font-family:'Montserrat' !important;
  left:24px;
  line-height:28.72px;
}
@media only screen and (min-width:937px) and (max-width:960px){
  font-size:20px;
  top:433px;
  font-family:'Montserrat' !important;
  left:24px;
  line-height:28.72px;
}
@media only screen and (min-width:961px) and (max-width:1023px){
  font-size:20px;
  top:533px;
  font-family:'Montserrat' !important;
  left:24px;
  line-height:28.72px;
}
@media only screen and (min-width:1024px) and (max-width:1080px)
{ 
  font-size: 21px;
  top: 480px;
}
@media only screen and (min-width:1081px) and (max-width:1280px)
{
  font-size: 21px;
  top: 480px;
}
@media only screen and (min-width:1281px)and (max-width:1463px)
{
  font-size: 24px;
  top: 490px;
}
@media only screen and (min-width:1366px)and (max-width:1385px)
{
  font-size: 22px;
  top: 485px;
}
`;



export const ImageWrapper = styled.div`
  cursor: pointer;
  display:flex;
  width:100%;
  margin-left:-30px;
  max-width: 100%;
  font-family:'Montserrat' !important;
  &:hover ${TextOverlay} {
  display: block;
  }
  @media only screen and (min-width:320px)and (max-width:480px){
    margin-left:-30px;

  }
  @media only screen and (min-width:481px)and (max-width:639px){
   
  }
  @media only screen and (min-width:768px)and (max-width:809px){
    padding-right: 15px;
    padding-left: 35px;
    margin-left: -30px;
  }
  @media only screen and (min-width:961px)and (max-width:1079px){
    padding-right: 15px;
    padding-left: 35px;
    margin-left: -30px;
  }
  @media only screen and (min-width:1080px)and (max-width:1116px){
    padding-right: 15px;
    padding-left: 35px;
  }
  @media only screen and (min-width:1280px)and (max-width:1365px){
    padding-right: 15px;
    padding-left: 8px;
  }
`;
export const Image = styled.img`
  width: ${({ isHovered }) => (isHovered ? '100%' : '252px')};
  height: 779px;
  padding-left: 8px;


  &:hover {
    width:100%;
    height: 779px;
  }


  @media screen and (min-width: 320px) and (max-width: 359px) {
    max-width: 440px;
    width:310px;
    height:525px;
    padding-left:15px;
    &:hover {
      width:310px;
      height: 525px;
    }
  }
  @media screen and (min-width: 360px) and (max-width: 366px) {
    max-width: 440px;
    width:324px;
    height:525px;
    padding-left:15px;
    &:hover {
      width:324px;
      height: 525px;
    }
  }
  @media screen and (min-width: 367px) and (max-width: 388px) {
   
    width:115%;
    height:525px;
    padding-left:15px;
    &:hover {
      width:115%;
      height: 525px;
    }
  }
  @media screen and (min-width: 389px) and (max-width: 440px) {
  
   width:114%;
    height:525px;
    padding-left:15px;
    &:hover {
     
    width:114%;
      height: 525px;
    }
  }
  @media screen and (min-width: 441px) and (max-width: 480px) {
  
  width:112%;
   height:525px;
   padding-left:13px;
   &:hover {
    
   width:112%;
     height: 525px;
   }
 }
  @media only screen and (min-width:481px)and (max-width:540px){
    width:113%;
    height:595px;
    padding-left:10px;
    &:hover {
      width:113%;
      height: 595px;
    }
  }
  @media only screen and (min-width:541px)and (max-width:580px){
  
  width:108%;
  height:595px;
  padding-left:10px;
  &:hover {
    width:108%;
    height: 595px;
  }
}
@media only screen and (min-width:581px)and (max-width:610px){
  
  width:112%;
  height:595px;
  padding-left:10px;
  &:hover {
    width:112%;
    height: 595px;
  }
}
  @media only screen and (min-width:611px)and (max-width:639px){
  
    width:116%;
    height:595px;
    padding-left:10px;
    &:hover {
      width:116%;
      height: 595px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 702px) {
    width: 106%;
    height:758px;
    padding-left:10px;
    &:hover {
      width:106%;
      height: 758px;
    }
  }
  @media only screen and (min-width: 703px) and (max-width: 730px) {
  
    width:110%;
    height:758px;
    padding-left:10px;
    &:hover {
      width:110%;
      height: 758px;
    }
  }
  @media only screen and (min-width: 731px) and (max-width: 767px) {
  
  width:113%;
  height:758px;
  padding-left:10px;
  &:hover {
    width:113%;
    height: 758px;
  }
}
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 114%;
    height:758px;
    padding-left:10px;
    &:hover {
      width:114%;
      height: 758px;
    }
  }

  @media only screen and (min-width: 810px) and (max-width:960px) {
    width: ${({ isHovered }) => (isHovered ? '100%' : '120px')};
    height:642px;
    &:hover {
      width:100%;
      height: 642px;
    }
  }
  
  @media only screen and (min-width:961px) and (max-width: 1000px) {
    
    width: ${({ isHovered }) => (isHovered ? '100%' : '145px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
  @media only screen and (min-width:1001px) and (max-width: 1079px) {
    
    width: ${({ isHovered }) => (isHovered ? '100%' : '145px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
  @media only screen and (min-width:1080px) and (max-width: 1116px) {
    
    width: ${({ isHovered }) => (isHovered ? '100%' : '175px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
  @media only screen and (min-width:1117px) and (max-width: 1175px) {
    max-width: ${({ isHovered }) => (isHovered ? '100%' : '195px')};
    width: ${({ isHovered }) => (isHovered ? '100%' : '185px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
  @media only screen and (min-width:1176px) and (max-width: 1209px) {
    max-width: ${({ isHovered }) => (isHovered ? '100%' : '195px')};
    width: ${({ isHovered }) => (isHovered ? '100%' : '185px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
    @media only screen and (min-width:1210px) and (max-width: 1279px) {
      max-width: ${({ isHovered }) => (isHovered ? '100%' : '195px')};
    width: ${({ isHovered }) => (isHovered ? '100%' : '185px')};
  
    &:hover {
      width:100%;
      height: 779px;
    }
  }
  @media screen and (min-width: 1280px) and (max-width:1309px) {
    width: ${({ isHovered }) => (isHovered ? '520.98px' : '207px')};
    height:779px;
    &:hover {
      width: 520.98px;
      height: 779px;
    }
  }
  @media screen and (min-width: 1310px) and (max-width:1325px) {
    width: ${({ isHovered }) => (isHovered ? '524.98px' : '213px')};
    height:779px;
    &:hover {
      width: 524.98px;
      height:779px;
    }
  }
  @media screen and (min-width: 1326px) and (max-width:1345px) {
    width: ${({ isHovered }) => (isHovered ? '524.98px' : '218px')};
    height:779px;
    &:hover {
      width: 524.98px;
      height:779px;
    }
  }
  @media screen and (min-width: 1346px) and (max-width:1365px) {
    width: ${({ isHovered }) => (isHovered ? '524.98px' : '228px')};
    height:779px;
    &:hover {
      width: 524.98px;
      height:779px;
    }
  }
  @media screen and (min-width: 1510px) and (max-width:1530px) {
    width: ${({ isHovered }) => (isHovered ? '100%' : '262px')};
    height:779px;
    &:hover {
      width: 100%;
      height:779px;
    }
  }
  @media screen and (min-width: 1531px) and (max-width:2800px) {
    width: ${({ isHovered }) => (isHovered ? '100%' : '268px')};
    height:779px;
    &:hover {
      width: 100%;
      height:779px;
    }
  }
`;



export const Images = styled.div`
 
  position: relative;
  cursor: pointer;
  @media only screen and (min-width:320px)and (max-width:480px){

}
@media only screen and (min-width:481px)and (max-width:767px){

}
@media only screen and (min-width:768px)and (max-width:1023px){

}
`;
export const Arrow1=styled.div`
height:69px;
@media only screen and (min-width:320px)and (max-width:480px){
height:59px;
}
@media only screen and (min-width:481px)and (max-width:767px){
  height:59px;
}
@media only screen and (min-width:768px)and (max-width:1023px){

}
`;
export const ArrowIcon = styled(FontAwesomeIcon)`
  margin-top: 10px;
  flex-direction: column;
  color:#000000;
  
  background-color:rgba(255, 255, 255, 0.39) ;
  border-radius:34px;
  width:14px;
  padding:10px 15px;
  outline:none;
  transition:  0.3s ease;
&:hover{
  background-color:#EE991C;
}
  @media only screen and (min-width:320px)and (max-width:480px){
    padding:10px 10px;
    margin-top:0;
}
@media only screen and (min-width:481px)and (max-width:767px){
  padding:5px 10px;
    margin-top:5px;
}
@media only screen and (min-width:768px)and (max-width:1023px){
padding:5px 10px;
margin-top:10px;
}
`;
export const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media only screen and (min-width:320px) and (max-width:358px){
  margin-left:38px;
}
  @media only screen and (min-width:359px) and (max-width:366px){
  margin-left:38px;
}
  @media only screen and (min-width:367px) and (max-width:388px){
  margin-left:38px;
}
@media only screen and (min-width:389px) and (max-width:440px){
  margin-left:36px;
}
@media only screen and (min-width:441px) and (max-width:480px){
  margin-left:44px;
}
@media only screen and (min-width:755px) and (max-width:809px){
  margin-top:-5px;
}
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.isActive ? '#C4C4C4' : 'rgba(255, 255, 255, 0.24) ')};
  width:${(props)=>(props.isActive ? '24px' :'8px')};
  border-radius: ${(props)=>(props.isActive ? '10px' :'8px')};
  margin: 0 10px;
  position: relative;
  top:-60px;
  left:-30px;
  cursor: pointer;
align-items: center;
justify-content: center;
text-align: center;


`;
export const But1=styled.div`
@media only screen and (min-width:320px) and (max-width:640px){
margin-top:20px;
}
`;
export const Button=styled.button`
  width:256.98px;
  height: 47.83px;
  font-size: 16px;
  font-weight:700;
  line-height: 19.5px;
  background: #ef991b;
  color:#000000;
  align-items: center;
  border-radius: 38px;
  margin-top:25px;
  border:none;
  @media only screen and (min-width:320px)and (max-width:480px){
   
    margin-top:0px;
}
@media only screen and (min-width:481px)and (max-width:639px){
    margin-left: 20px;
    margin-top:30px;
}

@media only screen and (min-width:640px)and (max-width:809px){
  margin-top:40px;
}
`; 