import React from "react";
import HeroSection from "../../components/Herosection/Index";
import ServicesSection from "../../components/Service/Index";
import CaseStudies from "../../components/CaseStudies/Index";
import Testimonial from "../../components/Testimonial/Index";
import VideoSection from "../../components/VideoSection";
import SectionFour from "../../components/SectionFour";
import PricingSection from "../../components/PricingSection";
import ContactInformation from "../../components/ContactInformation";
import FooterSection from "../../components/FooterSection";

const Home = () => {
  return (
    <>
   
      <HeroSection />
      <VideoSection />
       <ServicesSection />
      <SectionFour />
      <CaseStudies />
      <PricingSection />
      <Testimonial />
      <ContactInformation />
      <FooterSection />
    </>
  );
};

export default Home;
