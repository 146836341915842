import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Img01 from "../../assets/contactImg.png";
import Img02 from '../../assets/name.png';
import Img03 from '../../assets/email.png'
import Img04 from '../../assets/message.png';
import axios from 'axios'; 

import {
  PopupOverlay,
  PopupContent,
  CloseButton,
  FormContainer,
  ImageContainer,
  FormFields,
  Img,
  Img1,
  Form1,
  Form2,
  Form3,
  Button1,
  MainHeading,
  HeadingA,
  InputIcon,
  InputIcon1,
  Input,
  Textarea,
  Btn,
  CBtn
} from "./style";

const ContactFormPopup = ({ onClose }) => {
 const [formData, setFormData] = useState({
    website: "developer",
    name: "",
    email: "",
    message: ""
  });
  
  const [status, setStatus] = useState(null);
  const [statusColor, setStatusColor] = useState("black");

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const resetForm = () => {
    setFormData({
      website: "developer",
      name: "",
      email: "",
      message: ""
    });
  };

  const handleSend = async () => {
    if (!formData.name || !formData.email || !formData.message) {
      setStatus("Please fill in all fields.");
      setStatusColor("red");
      return;
    }

    if (!validateEmail(formData.email)) {
      setStatus("Please enter a valid email address.");
      setStatusColor("red");
      return;
    }

    try {
      const response = await axios.post("https://lmsbackend.liamcrest.com/api/register_query", formData, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (response.status === 200) {
        setStatus("Form submitted successfully!");
        setStatusColor("green");
        resetForm(); // Reset form data after successful submission
      } else {
        setStatus("Form submission failed.");
        setStatusColor("red");
      }
    } catch (error) {
      setStatus("Error sending form data: " + error.message);
      setStatusColor("red");
    }
  };
  return ReactDOM.createPortal(
    <PopupOverlay>
      <PopupContent>
        <CBtn>
          <CloseButton onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </CBtn>
        <MainHeading>
          <HeadingA>Have some questions?</HeadingA>
        </MainHeading>

        <FormContainer>
          <ImageContainer>
            <Img src={Img01} alt="Your Image" />
          </ImageContainer>
          <FormFields>
            <Form1>
              <InputIcon>
                <Img1 src={Img02} alt='name'/>
              </InputIcon>
              <Input
                type="text"
                id="name"
                placeholder="Your Name"
                onChange={handleInputChange}
                value={formData.name}
              />
            </Form1>
            <Form2>
              <InputIcon>
                <Img1 src={Img03} alt='email'/>
              </InputIcon>
              <Input
                type="email"
                id="email"
                placeholder="Your Email"
                onChange={handleInputChange}
                value={formData.email}
              />
            </Form2>
            <Form3>
              <InputIcon1>
                <Img1 src={Img04} alt='message'/>
              </InputIcon1>
              <Textarea
                id="message"
                placeholder="Your Message"
                onChange={handleInputChange}
                value={formData.message}
              />
            </Form3>
            <Btn>
              <Button1 onClick={handleSend}>Send Message</Button1>
            </Btn>
           {status && <h4 style={{ marginTop: "10px", color: statusColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{status}</h4>}
          </FormFields>
        </FormContainer>
      </PopupContent>
    </PopupOverlay>,
    document.body
  );
};

export default ContactFormPopup;
