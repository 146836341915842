import React from "react";

import {
  FeatureText,
  Section,
  TextWrapper,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  LineSec,
  ServiceWrapper,
  FeatureData1,
  FeatureData2,
  FImage,
  StyledServiceText,
  StyledServicesDiv,
  StyledFeatureText,
  StyledServiceHeading,
  StyledContainer,
  StyledServicePara,
} from "./styles";

import { ServicesData } from "../../Data/ServicesData";

const ServicesSection = () => {
  return (
    <Section id="Services">
      <StyledContainer>
        <StyledFeatureText>
          <StyledServicesDiv>
            <TextWrapper>
              {" "}
              <LineSec />
              <StyledServiceText>SERVICES</StyledServiceText>
            </TextWrapper>
            <StyledServiceHeading>
              Empower Your Success with Liam Crest Services
            </StyledServiceHeading>
          </StyledServicesDiv>
          <StyledServicePara>
            At Liam Crest, we're driven by a relentless commitment to
            excellence. Your experience with us is crafted to be nothing short
            of exceptional.
          </StyledServicePara>
        </StyledFeatureText>
        <ServiceWrapper>
          <FeatureWrapper>
            {ServicesData.map((el, index) => (
              <FeatureColumn key={index}>
                <FImage>
                  {" "}
                  <FeatureImageWrapper>{el.icon}</FeatureImageWrapper>{" "}
                </FImage>
                <FeatureData1>
                  <FeatureName>{el.name}</FeatureName>
                </FeatureData1>
                <FeatureData2>
                  <FeatureText>{el.description}</FeatureText>
                </FeatureData2>
              </FeatureColumn>
            ))}
          </FeatureWrapper>
        </ServiceWrapper>
      </StyledContainer>
    </Section>
  );
};

export default ServicesSection;
