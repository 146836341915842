import styled from "styled-components";


export const StyledInfo=styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
height:auto;
padding:4rem 0;
background: #f5f6f9;
`;
export const MainHeading=styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
transform: translateY(-15px);
`;
export const Header=styled.p`



 line-height: 21.91px;
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 25.04px;
  color: #16205d;
  @media only screen and (min-width:320px) and (max-width:640px){
  font-size: 14px;
 }
`;
export const Title=styled.h2`
  font-size: 24px;
  color: rgba(22, 32, 93, 1);
 font-family: "Montserrat";
 @media only screen and (min-width:320px) and (max-width:640px){
  font-size: 18px;
 }
 
`;