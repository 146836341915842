import styled from "styled-components";

export const StyledMain = styled.div`
  margin: 5% 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  &.showBackground {
    background-image: none;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    flex-direction: column;
    &.showBackground {
      background-image: url("../About1.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 50% auto;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    &.showBackground {
      background-image: url("../About1.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 50% auto;
    }
  }
`;
export const StyledA = styled.div`
  width: 40%;
  height: 484px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start-flex;
  padding: 0px 0px 0px 40px;
  &.showBackground {
    background-image: url("../About.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 400px auto;
  }
  @media only screen and (min-width: 1082px) and (max-width: 1281px) {
    height: 450px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    width: 90%;
    height: auto;
    align-items: center;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    &.showBackground {
      background-image: none;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: auto;
    align-items: center;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    &.showBackground {
      background-image: none;
    }
  }
  // @media only screen and (min-width: 640px) and (max-width: 1081px) {
  //   width: 100%;
  // }
  // @media only screen and (min-width: 321px) and (max-width: 639px) {
  //   flex-direction: column;
  //   justify-content: center;
  // }
  // @media only screen and (max-width: 320px) {
  //   flex-direction: column;
  //   justify-content: center;
  // }
`;
export const StyledDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  @media only screen and (min-width: 1082px) and (max-width: 1281px) {
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    width: 60%;
    align-items: center;
  }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   width: 50%;
  // }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 320px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledDiv1 = styled.div`
  width: 30%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 1082px) and (max-width: 1280px) {
    width: 35%;
  }
  @media only screen and (min-width: 701px) and (max-width: 1081px) {
    width: 18%;
    justify-content: space-around;
  }
  @media only screen and (min-width: 641px) and (max-width: 700px) {
    width: 20%;
    justify-content: space-around;
  }
  @media only screen and (min-width: 501px) and (max-width: 640px) {
    width: 25%;
    justify-content: space-around;
  }
  @media only screen and (min-width: 421px) and (max-width: 500px) {
    width: 30%;
    justify-content: space-around;
  }
  @media only screen and (min-width: 361px) and (max-width: 420px) {
    width: 35%;
    justify-content: space-around;
  }
  @media only screen and (min-width: 320px) and (max-width: 360px) {
    width: 40%;
    justify-content: space-around;
  }
  // @media only screen and (min-width: 810px) and (max-width: 1080px) {
  //   width: 35%;
  // }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   width: 40%;
  // }
`;
export const StyledLine = styled.div`
  width: 30px;
  height: 1.5px;
  flex-shrink: 0;
  background: #ee991c;
  @media only screen and (min-width: 810px) and (max-width: 1080px) {
    width: 25px;
  }
  @media only screen and (min-width: 320px) and (max-width: 639px) {
    width: 30px;
  }
  @media only screen and (max-width: 320px) {
    width: 20px;
  }
`;
export const StyledP1 = styled.p`
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.5%; /* 25.04px */
  letter-spacing: 2.16px;
  text-transform: uppercase;
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    font-size: 12px;
  }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   font-size: 12px;
  // }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledH3 = styled.h3`
  width: 95%;
  margin-top: 5%;
  color: #03375e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%; /* 39.04px */
  @media only screen and (min-width: 801px) and (max-width: 1081px) {
    font-size: 24px;
    text-align: center;
    margin-top: 1%;
    width: 70%;
  }
  @media only screen and (min-width: 641px) and (max-width: 800px) {
    font-size: 24px;
    text-align: center;
    margin-top: 1%;
    width: 80%;
  }
  // @media only screen and (min-width: 810px) and (max-width: 1080px) {
  //   font-size: 30px;
  // }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   font-size: 24px;
  // }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 20px;
    text-align: center;
    margin-top: 1%;
    width: 95%;
  }
`;
export const StyledText = styled.div`
  width: 90%;
  margin-bottom: 0%;
  display: flex;
  align-items: flex-start;
  @media only screen and (min-width: 1082px) and (max-width: 1281px) {
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  // @media only screen and (min-width: 320px) and (max-width: 639px) {
  //   width: 100%;
  // }
  // @media only screen and (max-width: 320px) {
  //   width: 100%;
  // }
`;
export const StyledP = styled.p`
  width: 90%;
  margin-top: 5%;
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.24px;
  @media only screen and (min-width: 1082px) and (max-width: 1281px) {
    width: 100%;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    font-size: 12px;
    text-align: center;
    width: 70%;
    margin-top: 2%;
  }
  @media only screen and (min-width: 641px) and (max-width: 800px) {
    font-size: 12px;
    text-align: center;
    width: 80%;
    margin-top: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    text-align: center;
    margin-top: 1%;
    width: 95%;
  }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   font-size: 12px;
  // }
  // @media only screen and (min-width: 320px) and (max-width: 639px) {
  //   width: 100%;
  //   font-size: 14px;
  //   text-align: center;
  // }
  // @media only screen and (max-width: 320px) {
  //   width: 100%;
  //   font-size: 14px;
  //   text-align: center;
  // }
`;
export const StyledVideoContainer = styled.div`
  width: 50%;
  height: 484px;
  object-fit: fill;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 0px 40px 0px 0px;
  @media only screen and (min-width: 1082px) and (max-width: 1281px) {
    height: 450px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    width: 90%;
    height: 400px;
    margin-top: 4%;
    padding: 0px 0px 0px 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 90%;
    height: 215px;
    margin-top: 4%;
    padding: 0px 0px 0px 0px;
  }
`;
export const StyledVideo = styled.video`
  width: 100%;
  height: 484px;
  object-fit: fill;
  margin-top: 0%;
  border-radius: 23px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  @media only screen and (min-width: 1081px) and (max-width: 1281px) {
    height: 450px;
  }
  @media only screen and (min-width: 641px) and (max-width: 1081px) {
    height: 400px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 215px;
  }
  // @media only screen and (min-width: 810px) and (max-width: 1081px) {
  //   height: 325px;
  // }
  // @media only screen and (min-width: 640px) and (max-width: 809px) {
  //   height: 300px;
  // }
  // @media only screen and (min-width: 320px) and (max-width: 639px) {
  //   height: 230px;
  // }
  // @media only screen and (max-width: 320px) {
  //   height: 200px;
  // }
`;
export const Overlay = styled.div`
  position: relative;
  top: -50%;
  left: 0;
  // width: 100%;
  // height: 100%;
  // display: block;
  // justify-content: center;
  // align-items: center;
  // background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "block" : "none")};
`;

export const PlayButton = styled.div`
  position: relative;
  top: -55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #92dfef;
  border-radius: 50%;
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "none" : "block")};
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-left: 20px solid rgba(22, 32, 93, 1);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    color: rgba(22, 32, 93, 1);
  }

  &:hover: {
    background-color: rgba(0, 0, 0, 0.7);
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    top: -58%;
    left: 50%;
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
`;

export const PauseButton = styled.div`
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #92dfef;
  border-radius: 50%;
  cursor: pointer;
  display: ${(props) => (props.isHidden ? "none" : "block")};

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 30%;
    width: 15%; /* Adjust width as needed */
    height: 40%;
    background: rgba(22, 32, 93, 1);
  }

  &::before {
    left: 30%;
  }

  &::after {
    left: 55%;
  }
  &:hover {
    // background-color: rgba(0, 0, 0, 0.7);
  }

  @media only screen and (min-width: 320px) and (max-width: 640px) {
    top: -58%;
    left: 50%;
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
`;
