import React, { useState } from "react";
import { Container } from "../../globalStyles";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { CardData, ProjectDetail1 } from "../../Data/CaseStudyData";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Heading3,
  LightText,
  Button5,
  ButtonRow5,
  CardDescription,
  CardDescription1,
  TwoCard,
  FirstCard,
  SecondCard,
  CardImage,
  CardTitle,
  ProjectDetail,
  Customhr1,
  Customhr,
  LineSec,
  ProjectDetailItem,
  ProjectDetailContainer,
  ProjectDescription,
  ProjectNumber,
  ProjectImg,
  Button21,
  Button22,
  Button23,
  Button24,
  TextLeft
} from "./Styles";

export function CaseStudies() {
  const cardSets = [
    [
      {
        image: CardData[0].image1,
        title: CardData[0].title,
        desc: CardData[0].desc,
      },
      {
        image: CardData[1].image2,
        title: CardData[1].title,
        desc: CardData[1].desc,
      },
    ],
    [
      {
        image: CardData[2].image3,
        title: CardData[2].title,
        desc: CardData[2].desc,
      },
      {
        image: CardData[3].image4,
        title: CardData[3].title,
        desc: CardData[3].desc,
      },
    ],
    [
      {
        image: CardData[4].image5,
        title: CardData[4].title,
        desc: CardData[4].desc,
      },
      {
        image: CardData[5].image6,
        title: CardData[5].title,
        desc: CardData[5].desc,
      },
    ],
    [
      {
        image: CardData[6].image7,
        title: CardData[6].title,
        desc: CardData[6].desc,
      },
      {
        image: CardData[7].image8,
        title: CardData[7].title,
        desc: CardData[7].desc,
      },
    ],
  ];

  const [activeButton, setActiveButton] = useState(0);
  const [counterOn, setCounterOn] = useState(false);
  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const activeCards = cardSets[activeButton];

  const homeOne = {
    btn21: "UI/UX Design",
    btn22: "Digital Marketing",
    btn23: "E-Learning Management",
    btn24: "Development",
  };

  return (
    <>
      <InfoSec id="Work">
        <Container>
          <InfoRow>
            <InfoColumn>
              <TextWrapper>
             <TextLeft>   <LineSec />
                <LightText> CASE STUDIES </LightText></TextLeft>
                <Heading3>
                  Delivering Excellence - Our Commitment to Limited Clientele
                  for <span>Top-Tier Service</span>
                </Heading3>
                <ButtonRow5>
                  <Button21
                    isActive={activeButton === 0}
                    onClick={() => handleButtonClick(0)}
                  >
                    {homeOne.btn21}
                  </Button21>
                  <Button22
                    isActive={activeButton === 1}
                    onClick={() => handleButtonClick(1)}
                  >
                    {homeOne.btn22}
                  </Button22>
                  <Button23
                    isActive={activeButton === 2}
                    onClick={() => handleButtonClick(2)}
                  >
                    {homeOne.btn23}
                  </Button23>
                  <Button24
                    isActive={activeButton === 3}
                    onClick={() => handleButtonClick(3)}
                  >
                    {homeOne.btn24}
                  </Button24>
                </ButtonRow5>
                <CardDescription>
                  <TwoCard>
                    <FirstCard>
                      <CardImage src={activeCards[0].image} alt="Card 1" />
                      <CardTitle>{activeCards[0].title}</CardTitle>
                      <CardDescription1>{activeCards[0].desc}</CardDescription1>
                    </FirstCard>
                    <SecondCard>
                      <CardImage src={activeCards[1].image} alt="Card 2" />
                      <CardTitle>{activeCards[1].title}</CardTitle>
                      <CardDescription1>{activeCards[1].desc}</CardDescription1>
                    </SecondCard>
                  </TwoCard>
                </CardDescription>
              </TextWrapper>
            </InfoColumn>
          </InfoRow>
          <ProjectDetail>
            <Customhr />
            <Customhr1 />
            <ProjectDetailContainer>
              {ProjectDetail1.map((detail, index) => (
                <ProjectDetailItem key={index}>
                  <ScrollTrigger
                    onEnter={() => setCounterOn(true)}
                    onExit={() => setCounterOn(false)}
                  >
                    <ProjectNumber>
                      {counterOn && (
                        <CountUp
                          start={activeButton === index ? 0 : null}
                          end={parseInt(detail.ProjectNumber)}
                          duration={2}
                          delay={0}
                        />
                      )}
                      +
                    </ProjectNumber>
                  </ScrollTrigger>
                  <ProjectImg>{detail.ProjectImg}</ProjectImg>
                  <ProjectDescription>
                    {detail.ProjectDetail}
                  </ProjectDescription>
                </ProjectDetailItem>
              ))}
            </ProjectDetailContainer>
          </ProjectDetail>
        </Container>
      </InfoSec>
    </>
  );
}

export default CaseStudies;
