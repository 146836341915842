import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  background: #ffffff;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat";
  position: sticky;
  top: -8px;
  z-index: 1;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    top: -10px;
    height: 60px;
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    width: 100%;
    top: -10px;
    height: 60px;
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    width: 100%;
    top: -10px;
    height: 60px;
  }
  @media only screen and (min-width: 640px) and (max-width: 700px) {
    width: 100%;
    top: -15px;
  }
  @media only screen and (min-width: 701px) and (max-width: 767px) {
    width: 100%;
    top: -10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 100%;
    top: -15px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 100%;
    top: -15px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1179px) {
    top: -15px;
  }
`;


export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;

  z-index: 1;
  width: 100%;
  max-width: 1550px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  padding-left: 90px;
  @media screen and (min-width: 992px) and (max-width: 1079px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    top: -10px;
  }
`;

export const NavLogo = styled.img`

  margin-left: -30px;
  width: 79px;
  height: 79px;
  @media only screen and (min-width: 320px) and (max-width: 388px) {
    height: 40px;
    width: 40px;
    margin-top: 45px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    height: 40px;
    width: 40px;
    margin-top: 45px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    height: 40px;
    width: 40px;
    margin-top: 45px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    height: 67px;
    width: 67px;
    margin-top: 35px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    height: 67px;
    width: 67px;
    margin-top: 35px;
    margin-left: 20px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    height: 62px;
    width: 62px;
    margin-top: 35px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    height: 62px;
    width: 62px;
    margin-top: 15px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1116px) {
    width: 74px;
    height: 74px;
    margin-left: -57px;
  }
  @media only screen and (min-width: 1117px) and (max-width: 1165px) {
    width: 74px;
    height: 74px;
    margin-left: -38px;
  }
  @media only screen and (min-width: 1166px) and (max-width: 1279px) {
    width: 74px;
    height: 74px;
    margin-left: -25px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 74px;
    height: 74px;
    margin-left: -20px;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 366px) {
    display: block;
    padding-top: 45px;
    padding-left: 178px;
    margin-left: auto;
    position: relative;
    left: 48px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 367px) and (max-width: 388px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 83px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  
  @media only screen and (min-width: 389px) and (max-width: 435px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 31%;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 436px) and (max-width: 480px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left:40%;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 481px) and (max-width: 506px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 196px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 507px) and (max-width: 540px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 228px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 541px) and (max-width: 581px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 250px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 582px) and (max-width: 639px) {
    display: block;
    padding-top: 45px;
    padding-left: 160px;
    margin-left: auto;
    position: relative;
    left: 288px;
    margin-right: auto;
    font-size: 1.8rem;

    height: 15.5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 640px) and (max-width: 666px) {
    display: block;
    padding-top: 45px;
    padding-left: 440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position:relative;
    left: 20px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 667px) and (max-width: 700px) {
    display: block;
    padding-top: 45px;
    padding-left: 440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position:relative;
    left: 58px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 701px) and (max-width: 767px) {
    display: block;
    padding-top: 28px;
    padding-left: 440px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    left: 82px;
    position: relative;
    height: 44px;
    cursor: pointer;
    top: 20px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    display: block;
    padding-top: 45px;
    padding-left: 450px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 80px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 810px) and (max-width: 830px) {
    display: block;
    padding-top: 50px;
    padding-left: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 65px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 831px) and (max-width: 870px) {
    display: block;
    padding-top: 50px;
    padding-left: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 84px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 871px) and (max-width: 900px) {
    display: block;
    padding-top: 50px;
    padding-left: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 133px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 901px) and (max-width: 930px) {
    display: block;
    padding-top: 50px;
    padding-left: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 170px;
    height: 44px;
    cursor: pointer;
  }
  @media only screen and (min-width: 931px) and (max-width: 960px) {
    display: block;
    padding-top: 50px;
    padding-left: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
    position: relative;
    left: 190px;
    height: 44px;
    cursor: pointer;
  }

  /* @media screen and (max-width: 960px) { */
  /* display: block;
    position: absolute;
    top: 30px;
    right: 40px;
    margin-left: auto;
    margin-right:auto;
    font-size: 1.8rem;
    /* cursor: pointer; */
  /* } */
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  position: relative;
  margin-left: 654px;
  top: 7px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 481px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 60px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 60px;
    left: ${({ click }) => (click ? 0 : "-120%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 810px) and (max-width: 870px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
    margin-left: 0;
  }
  @media only screen and (min-width: 871px) and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120vh;
    position: absolute;
    margin-left: 0;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #16205d;
  }
  @media only screen and (min-width: 961px) and (max-width: 1000px) {
    left: -200px;
    top: 10px;
    margin-left: 603px;
  }
  @media only screen and (min-width: 1001px) and (max-width: 1079px) {
    left: -200px;
    top: 10px;
    margin-left: 645px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1165px) {
    left: -154px;
  }
  @media only screen and (min-width: 1166px) and (max-width: 1195px) {
    left: -150px;
  }
  @media only screen and (min-width: 1196px) and (max-width: 1279px) {
    left: -116px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1319px) {
    left: -55px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1320px) and (max-width: 1345px) {
    left: -41px;
    font-size: 12px;
  }
  @media only screen and (min-width: 1346px) and (max-width: 1365px) {
    left: -17px;
    font-size: 12px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1400px) {
    left: -73px;
    font-size: 12px;
  }
  @media screen and (min-width: 1401px) and (max-width: 1460px) {
    left: -57px;
    font-size: 12px;
  }
`;

export const NavItem = styled.li`
  height: 20px;
  border-bottom: 2px solid transparent;
  margin-left: 40px;

  &:hover {
    border-bottom: 1px solid #16205d;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    margin-left: 30px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;
export const NavItemBtn = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled.span`
  color: #03375e;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 13px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 16px;
  letter-spacing: -0.4px;

  @media screen and (max-width: 960px) {
    text-align: center;
    transform: translateY(40px);
    padding-right: 40px;
    width: 100%;
    display: table;
    color: #fff;
    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  @media screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }
  @media screen and (min-width: 481px) and (max-width: 639px) {
    padding: 0;
  }
  @media screen and (min-width: 640px) and (max-width: 767px) {
    padding: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 809px) {
    padding: 0;
  }
  @media screen and (min-width: 810px) and (max-width: 960px) {
    padding: 0;
  }
`;

export const Button2 = styled.button`
  border-radius: 38px;
  background: #03375e;
  white-space: nowrap;
  width: 134px;
  height: 44px;
  color: #ffffff;
  font-size: 14px;
  outline: 1px solid #03375e;
  border: none;
  font-family: "Montserrat";
  cursor: pointer;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  position: relative;
  margin-left: -200px;
  &:hover{
    background-color: #EE991C;
    border:none;
    outline: none;
    color:#000000;
  }
  @media screen and (min-width: 320px) and (max-width: 388px) {
    width: 78px;
    height: 22px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 17px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 389px) and (max-width: 480px) {
    width: 78px;
    height: 22px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 30px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 481px) and (max-width: 540px) {
    width: 78px;
    height: 22px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 35px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 541px) and (max-width: 639px) {
    width: 78px;
    height: 22px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 54px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 640px) and (max-width: 700px) {
    width: 91px;
    height: 33px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: -50px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 701px) and (max-width: 767px) {
    width: 91px;
    height: 33px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: -10px;
    margin-top: 5px;
    font-family: 600;
  }
  @media screen and (min-width: 768px) and (max-width: 809px) {
    width: 91px;
    height: 33px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: -20px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 810px) and (max-width: 870px) {
    width: 91px;
    height: 33px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: -25px;
    margin-top: 10px;
    font-family: 600;
  }
  @media screen and (min-width: 871px) and (max-width: 960px) {
    width: 91px;
    height: 33px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 0px;
    margin-top: 10px;
    font-family: 600;
  }
  @media screen and (min-width: 961px) and (max-width: 1000px) {
    width: 100px;
    height: 44px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 0px;
    margin-top: 10px;
    font-family: 600;
  }
  @media screen and (min-width: 1001px) and (max-width: 1079px) {
    width: 100px;
    height: 44px;
    border-radius: 38px;
    font-size: 10px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    left: 0px;
    margin-top: 10px;
    font-family: 600;
  }
  @media screen and (min-width: 1080px) and (max-width: 1165px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    text-align: center;
    left: -10px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 1166px) and (max-width: 1279px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    text-align: center;
    left: -10px;
    margin-top: 0px;
    font-family: 600;
  }
  @media screen and (min-width: 1280px) and (max-width: 1297px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: 600;
    margin-left: 20px;
    position: relative;
    left: -51px;
  }
  @media screen and (min-width: 1298px) and (max-width: 1309px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: 600;
    margin-left: 20px;
    position: relative;
    left: -70px;
  }
  @media screen and (min-width: 1310px) and (max-width: 1345px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: 600;
    margin-left: 20px;
    position: relative;
    left: -58px;
  }

  @media screen and (min-width: 1346px) and (max-width: 1365px) {
    width: 134px;
    height: 44px;
    border-radius: 38px;
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat";
    border: none;
    line-height: 16px;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: 600;
    margin-left: 20px;
    position: relative;
    left: -44px;
  }
  /* @media screen and (max-width: 960px) {
    width: 70%;
  }
 */
`;

// export const Button3 = styled.button`
//   border-radius: 38px;
//   background: #03375e;
//   white-space: nowrap;
//   width: 145px;
//   height: 44px;
//   color: #ffffff;
//   font-size: 14px;
//   outline: 1px solid #03375e;
//   border: none;
//   font-family: "Montserrat";
//   cursor: pointer;
//   letter-spacing: 0.5px;
//   line-height: 16px;
//   font-weight: 500;
//   cursor: pointer;
//   &:hover {
//     background-color: #ee991c;
//   }
//   @media screen and (min-width: 320px) and (max-width: 388px) {
//     width: 78px;
//     height: 22px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -334px;
//     margin-top: 0px;
//   }

//   @media screen and (min-width: 389px) and (max-width: 480px) {
//     width: 78px;
//     height: 22px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -269px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 481px) and (max-width: 540px) {
//     width: 78px;
//     height: 22px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -170px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 541px) and (max-width: 639px) {
//     width: 78px;
//     height: 22px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -100px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 640px) and (max-width: 700px) {
//     width: 91px;
//     height: 33px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -360px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 701px) and (max-width: 767px) {
//     width: 91px;
//     height: 33px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -290px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 768px) and (max-width: 809px) {
//     width: 91px;
//     height: 33px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -170px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 810px) and (max-width: 870px) {
//     width: 91px;
//     height: 33px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -300px;
//     margin-top: 10px;
//   }
//   @media screen and (min-width: 871px) and (max-width: 960px) {
//     width: 91px;
//     height: 33px;
//     border-radius: 38px;
//     font-size: 10px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -180px;
//     margin-top: 10px;
//   }

//   @media screen and (min-width: 961px) and (max-width: 1000px) {
//     width: 134px;
//     height: 44px;
//     border-radius: 38px;
//     font-size: 14px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -80px;
//     margin-top: 10px;
//   }
//   @media screen and (min-width: 1001px) and (max-width: 1079px) {
//     width: 134px;
//     height: 44px;
//     border-radius: 38px;
//     font-size: 14px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -80px;
//     margin-top: 10px;
//   }
//   @media screen and (min-width: 1080px) and (max-width: 1165px) {
//     width: 134px;
//     height: 44px;
//     border-radius: 38px;
//     font-size: 14px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -80px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 1166px) and (max-width: 1279px) {
//     width: 134px;
//     height: 44px;
//     border-radius: 38px;
//     font-size: 14px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -80px;
//     margin-top: 0px;
//   }
//   @media screen and (min-width: 1280px) and (max-width: 1365px) {
//     width: 134px;
//     height: 44px;
//     border-radius: 38px;
//     font-size: 14px;
//     text-align: center;
//     font-family: "Montserrat";
//     border: none;
//     line-height: 16px;
//     margin-left: -160px;
//     margin-top: 0px;
//   }

//   /* @media screen and (max-width: 960px) {
//   width: 70%;
//   margin-top:-80px;
//   background:#EE991C;
//   color:#000000;
// } */
// `;
