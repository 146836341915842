import styled from "styled-components";

export const StyledMain = styled.div`
  margin: 0% 0 0% 0;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 800px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 550px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 480px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 400px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 580px;
    flex-direction: column;
  }
`;
export const StyledA = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100%;
  }
`;
export const StyledText = styled.div`
  width: 80%;
  height: 422px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // margin-top: 10%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 275px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 240px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 200px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 80%;
    height: 180px;
  }
`;
export const StyledH2 = styled.h2`
  color: #03375e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 87.5% */
  text-underline-offset: 0.5em;
  margin-bottom: 4%;
  span {
    text-decoration: underline;
    text-decoration-color: #ee991c;
    color: #03375e;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 87.5% */
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-bottom: 2%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    span {
      font-size: 24px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    span {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  }
`;
export const StyledP = styled.p`
  color: #797267;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24.5px; /* 153.125% */
  margin-top: 4%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    margin-top: 0%;
    line-height: 17px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    margin-top: 0%;
    line-height: 15px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    margin-top: 0%;
    line-height: 15px;
  }
`;
export const StyledIconRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4%;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: 1%;
  }
`;
const svgPhone = `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42" fill="none">
  <circle cx="21" cy="21" r="21" fill="#EE991C"/>
</svg>
`;
const svgMail = `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42" fill="none">
  <circle cx="21" cy="21" r="21" fill="#EE991C"/>
</svg>
`;
export const StyledIcon = styled.div`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 30%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 32%;
  }
  @media only screen and (min-width: 711px) and (max-width: 810px) {
    width: 32%;
  }
  @media only screen and (min-width: 641px) and (max-width: 710px) {
    width: 32%;
    gap: 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 32%;
    justify-content: flex-start;
    gap: 15px;
  }
`;
export const StyledIcon1 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 50%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 50%;
  }
  @media only screen and (min-width: 711px) and (max-width: 810px) {
    width: 50%;
  }
  @media only screen and (min-width: 641px) and (max-width: 710px) {
    width: 50%;
    gap: 0px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 50%;
    justify-content: flex-start;
    gap: 15px;
  }
`;
export const Icon = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: url("data:image/svg+xml;utf8,${encodeURIComponent(svgPhone)}");
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 38px;
    height: 38px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 24px;
    height: 24px;
  }
`;
export const Icon1 = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: url("data:image/svg+xml;utf8,${encodeURIComponent(svgMail)}");
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 38px;
    height: 38px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 30px;
    height: 30px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 24px;
    height: 24px;
  }
`;
export const StyledP1 = styled.div`
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 7px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 5px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 7px;
  }
`;
export const StyledP2 = styled.p`
  color: #797267;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin-top: 4%;
  & span {
    color: #03375e;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 14px;
    & span {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 12px;
    margin-top: 0%;
    line-height: 17px;
    & span {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 10px;
    margin-top: 0%;
    line-height: 12px;
    span {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 10px;
    margin-top: 0%;
    line-height: 12px;
    span {
      font-size: 10px;
    }
  }
`;
export const StyledB = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(/image12.png);
  background-size: contain;
  // margin-top: 8.5%;
  @media only screen and (min-width: 1537px) and (max-width: 3000px) {
    height: 380px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 380px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 275px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 240px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 200px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 180px;
  }
`;
export const StyledContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const StyledH1 = styled.h1`
  color: #000000;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 87.5% */
  text-underline-offset: 0.3em;

  span {
    text-decoration: underline;
    text-decoration-color: #03375e;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 24px;
    span {
      font-size: 24px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 20px;
    span {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  }
`;
export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: row;
  gap: 10px;
  column-gap: 10px;
  // justify-content: space-around;
  align-items: center;
  margin-top: 14%;
  @media only screen and (min-width: 538px) and (max-width: 640px) {
    gap: 5px;
    width: 82%;
    margin-top: 7%;
  }
  @media only screen and (min-width: 376px) and (max-width: 537px) {
    gap: 5px;
    margin-top: 7%;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    gap: 5px;
    margin-top: 7%;
  }
`;
export const StyledDiv1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
`;
export const StyledSelect = styled.select`
  width: 262px;
  height: 53px;
  flex-shrink: 0;
  background: #fff;
  color: #03375e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 212px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 162px;
    height: 34px;
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 140px;
    height: 31px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 110px;
    height: 22px;
    font-size: 8px;
  }
  @media only screen and (min-width: 376px) and (max-width: 640px) {
    width: 140px;
    height: 31px;
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 122px;
    height: 31px;
    font-size: 10px;
  }
`;
export const StyledOption = styled.option`
  color: #03375e;
  width: 262px;
  height: 53px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 8px;
  }
`;
export const StyledInput = styled.input`
  width: 262px;
  height: 53px;
  flex-shrink: 0;
  border: none;
  background: #fff;
  color: rgba(3, 55, 94, 0.39);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  // font-weight: 600;
  line-height: 28px; /* 175% */
  padding: 1%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 212px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 162px;
    height: 34px;
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 140px;
    height: 31px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 110px;
    height: 22px;
    font-size: 8px;
  }
  @media only screen and (min-width: 376px) and (max-width: 640px) {
    width: 140px;
    height: 31px;
    font-size: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 122px;
    height: 31px;
    font-size: 10px;
  }
`;
export const StyledButton = styled.button`
  cursor: pointer;
  width: 106px;
  height: 50px;
  margin-right: 14%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #16205d;
  border: none;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  &: hover {
    color: #000;
    background: white;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 106px;
    height: 40px;
    font-size: 16px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 106px;
    height: 34px;
    font-size: 12px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 80px;
    height: 30px;
    font-size: 10px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 60px;
    height: 23px;
    font-size: 8px;
    margin-right: 18%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 80px;
    height: 30px;
    font-size: 10px;
    margin-right: 18%;
  }
`;
export const StyledMap = styled.div`
  width: 55%;
  height: 800px;
  border: none;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 800px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 550px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 480px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 400px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    @media only screen and (min-width: 320px) and (max-width: 640px) {
      display: block;
      width: 100%;
      height: 220px;
    }
  }
`;
export const StyledMapM = styled.img`
  display: none;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    display: none;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 480px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 400px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: none;
  }
`;
