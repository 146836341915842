import styled from "styled-components";

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1;

`;

export const PopupContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height:601px;
  max-width: 1025px;
  width: 100%;
  transform: translateY(74px);
  @media only screen and (min-width:1080px) and (max-width:1279px){
    width:90%;
    height:544px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:810px) and (max-width:1079px){
    width:90%;
    height:427.93px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:640px) and (max-width:809px){
    width:68%;
    height:583px;
    transform: translateY(74px);
  }
  @media only screen and (min-width:320px) and (max-width:639px){
    width:80%;
    height:48%;
    transform: translateY(50px);
  }
`;
export const CBtn=styled.div`
        margin: -2% -2% 0 0;
    float: right;
  width: 44px;
  height: 44px;
  background: #92dfef;
  color:#000000;
 border-radius: 20px;
 cursor: pointer;
 @media only screen and (min-width:320px) and (max-width:639px){
  width:17.69px;
  height:17.69px;
 } 
`;
export const CloseButton = styled.span`
     cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    margin-top: 22%;
    width: 100%;
    height: 23px;
    @media only screen and (min-width:320px) and (max-width:639px){
      margin-top:-9%;
    }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top:5%;
  @media only screen and (min-width:810px) and (max-width:1079px){
    margin-top:3%;
  }
  @media only screen and (min-width:640px) and (max-width:809px){
     flex-direction: column;
     }
     @media only screen and (min-width:320px) and (max-width:639px){
     flex-direction: column;
     }
`;

export const ImageContainer = styled.div`
  flex: 1;
  padding: 0 10px;
  width:50%;
  margin-left:4%;
  @media only screen and (min-width: 640px) and (max-width:809px){
    margin-left: 0;
    margin-top: 10px;
  }
  @media only screen and (min-width: 320px) and (max-width:639px){
    margin-left: 0;
   padding:0;

  }
`;

export const FormFields = styled.div`
  flex: 1;
  padding: 10px 10px;
  width:40%;
  margin-left:2.5%;
  @media only screen and (min-width:1080px) and (max-width:1279px){
    margin-left:0;
    margin-top:-2%;
  }
  @media only screen and (min-width:640px) and (max-width:809px){
    margin-left: 0;
    padding:0;
  }
  @media only screen and (min-width:640px) and (max-width:809px){
    width: 78%;
    margin-left: 6.5%;
    margin-top:7%;
  }
  @media only screen and (min-width:320px) and (max-width:639px){
    width: 86%;
    margin-left: 6.5%;
    
  }
`;
export const Img = styled.img`
width:451px;
@media only screen and (min-width:1080px) and (max-width:1279px){
 width:92%;
 height: 258.8px;
}
@media only screen and (min-width:810px) and (max-width:1079px){
 width: 100%;
 height: 204.8px;
}
 @media only screen and (min-width:640px) and (max-width:809px){
 width: 100%;
 height:129.61px;
}
@media only screen and (min-width:320px) and (max-width:639px){
 width:92%;
 height:86.32px;
}
`;
export const Form1=styled.div`
align-items: center;
display: flex;
justify-content: space-between;

`;
export const Form2=styled.div`
align-items: center;
display: flex;
margin-top:5%;
`;
export const Form3=styled.div`

display: flex;
margin-top:5%;
`;
export const Btn=styled.div`
align-items: center;
display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
`;
export const Button1= styled.button`
color:#000000;
background-color: #92DFEF;
width:90%;
height:52px;
border-radius:51px;
border:none;
font-family: 'Montserrat';
align-items: center;
text-align: center;
font-size:16px;
font-weight: 600;
cursor:pointer;
@media only screen and (min-width:810px) and (max-width:1079px){
  width:80%;
  height: 35.97px;
  font-size:13px;
}
@media only screen and (min-width:640px) and (max-width:809px){
  width:88%;
  height: 41px;
  font-size:13px;
}
@media only screen and (min-width:320px) and (max-width:639px){
  width:88%;
  height: 26px;
  font-size:9px;
}
`;
export const MainHeading=styled.div`
width:100%;
align-items: center;
justify-content: center;
text-align: center;
margin-top:7%;
@media only screen and (min-width:810px) and (max-width:1079px){
  margin-top:7%;
}

@media only screen and (min-width:320px) and (max-width:639px){
  margin-top:2%;
}
`;
export const HeadingA=styled.h1`
font-size: 36px;
font-weight:600;
height: 47px;
line-height: 43.48px;
color: #16205d;
font-family:'Montserrat';

@media only screen and (min-width:1080px) and (max-width:1279px){
  font-size:32px;
}
@media only screen and (min-width:810px) and (max-width:1079px){
  font-size:26px;
}
@media only screen and (min-width:640px) and (max-width:809px){
  font-size:26px;
}
@media only screen and (min-width:320px) and (max-width:639px){
  font-size:16px;
}
`;

export const InputIcon=styled.div`

position: absolute;
margin-left: 1%;
@media only screen and (min-width:640px) and (max-width:810px){
  margin-left:2%;
 
}
@media only screen and (min-width:320px) and (max-width:639px){
  margin-left:2%;
}
`;
export const InputIcon1=styled.div`

position: absolute;
margin-left: 1%;
margin-top:2.3%;
@media only screen and (min-width:810px) and (max-width:1079px){
  margin-top:1.3%;
}

@media only screen and (min-width:640px) and (max-width:809px){
  margin-left:2%;
  margin-top:1.5%;
}
@media only screen and (min-width:320px) and (max-width:639px){
  margin-left:2%;
  margin-top:1.5%;
}
`;
export const Img1=styled.img`

align-items:center;

color:#03375E;
@media only screen and (min-width:640px) and (max-width:809px){
  width:10px;
  height:10px;
}
@media only screen and (min-width:320px) and (max-width:639px){
  width:10px;
  height:10px;
}
`;


export const Input=styled.input`
width:90%;
height: 52px;
border-radius:7px;
border:1px solid #16205D;
padding-left: 45px;

@media only screen and (min-width:810px) and (max-width:1079px){
  width:80%;
  height:35.97px;
}
@media only screen and (min-width:640px) and (max-width:809px){
  width:90%;
  height:38px;
  padding-left: 30px;
  &::placeholder {
   font-size: 11px;
}
}
@media only screen and (min-width:320px) and (max-width:639px){
  width:90%;
  height:24px;
  padding-left: 30px;
  &::placeholder {
   
    font-size: 9px;
}
}
`;
export const Textarea=styled.textarea`
width:90%;
height:102px;
border-radius:7px;
border:1px solid #16205D;
padding-left: 45px;
padding-top: 20px;
@media only screen and (min-width:810px) and (max-width:1079px){
  width:80%;
  height:70.55px;
  padding-top:9px;
}
@media only screen and (min-width:640px) and (max-width:809px){
  width:90%;
  height:70.55px;
  padding-top:9px;
  padding-left: 30px;
  &::placeholder {
   
   font-size: 11px;
}
}
@media only screen and (min-width:320px) and (max-width:639px){
  width:90%;
  height:41.22px;
  padding-top:7px;
  padding-left: 30px;
  &::placeholder {
   
   font-size: 9px;
}
}

`;