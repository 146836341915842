import React from "react";
import "./App.css";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Index";
import Home from "../src/pages/Home/Home";
import SectionFour from "./components/SectionFour";
import VideoSection from "./components/VideoSection";
import PricingSection from "./components/PricingSection";
import ContactInformation from "./components/ContactInformation/index";
import FooterSection from "./components/FooterSection/index";
import Popup from "./components/PricingPopup";
import HeroSection from "./components/Herosection/Index";
import CaseStudies from "./components/CaseStudies/Index";
import Testimonial from "./components/Testimonial/Index";
import Service from "./components/Service/Index";
import DetailForm from "./components/DetailForm";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/herosection" exact Component={<HeroSection />} />
        <Route path="/casestudies" exact Component={<CaseStudies />} />
        <Route path="/testimonial" exact Component={<Testimonial />} />
        <Route path="/service" exact Component={<Service />} />
        <Route path="/contact" element={<ContactInformation />} />
        <Route path="/footer" element={<FooterSection />} />
        <Route path="/pricingsection" element={<PricingSection />} />
        <Route path="/sectionfour" element={<SectionFour />} />
        <Route path="/videosection" element={<VideoSection />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/detailform" element={<DetailForm/>}/>
      </Routes>
    </Router>
  );
}

export default App;