import React, { useState } from "react";
import ContactFormPopup from "../ContactPopUp/index";
import {
  A,
  StyledA,
  StyledB,
  StyledBtn,
  StyledColumn,
  StyledColumn1,
  StyledContent,
  StyledH3,
  StyledInput,
  StyledInputRow,
  StyledLine,
  StyledLogo,
  StyledMain,
  StyledP,
  StyledP1,
  StyledROw,
  StyledText,
  StyledBtnA,
  IconRow,
  StyledIcon,
  StyledIcon2,
  StyledIcon3,
  StyledIcon4,
  StyledIcon1,
  StyledIcon5,
  StyledScroll,
  svgTwitter,
  svgInsta,
  svgLinkIn,
  svgFBook,
  svg,
  svgYoutube,
} from "./styles";

function FooterSection() {
  const [isFormOpen, setFormOpen] = useState(false);

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <StyledMain>
      <StyledContent>
        <StyledROw>
          <StyledA>
            <StyledLogo src="/img1.png" alt="img"></StyledLogo>
            <StyledP>
              We're a team of experienced software engineers, developers, and
              tech enthusiasts, dedicated to crafting cutting-edge digital
              solutions that exceed expectations.
            </StyledP>
            <StyledInputRow>
              <StyledInput placeholder="Enter your Email"></StyledInput>
              <StyledBtn>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M23.7136 8.70711C24.1041 8.31658 24.1041 7.68342 23.7136 7.2929L17.3496 0.928933C16.9591 0.538409 16.3259 0.538409 15.9354 0.928933C15.5449 1.31946 15.5449 1.95262 15.9354 2.34315L21.5923 8L15.9354 13.6569C15.5449 14.0474 15.5449 14.6805 15.9354 15.0711C16.3259 15.4616 16.9591 15.4616 17.3496 15.0711L23.7136 8.70711ZM0.515625 9L23.0065 9L23.0065 7L0.515625 7L0.515625 9Z"
                    fill="black"
                  />
                </svg>
              </StyledBtn>
            </StyledInputRow>
          </StyledA>
          <StyledB>
            <StyledColumn>
              <StyledScroll onClick={() => scrollToSection("Home")}>
                Home
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("About")}>
                About us
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Services")}>
                Services
              </StyledScroll>
            </StyledColumn>
            <StyledColumn>
              <StyledScroll onClick={() => scrollToSection("Work")}>
                Projects
              </StyledScroll>
              <StyledScroll onClick={() => scrollToSection("Pricing")}>
                Pricing
              </StyledScroll>
              <StyledBtnA onClick={openForm}>Contact</StyledBtnA>

              {isFormOpen && <ContactFormPopup onClose={closeForm} />}
            </StyledColumn>
            <StyledColumn1>
              <A href="#">Follow us</A>
              <IconRow>
                <A href="https://www.instagram.com/liam.crest.corp/">
                  <StyledIcon>
                    <div dangerouslySetInnerHTML={{ __html: svgInsta }} />
                  </StyledIcon>
                </A>
                <A href="https://twitter.com/LiamCrestCorp">
                  <StyledIcon5>
                    <div dangerouslySetInnerHTML={{ __html: svgTwitter }} />
                  </StyledIcon5>
                </A>
                <A href="https://www.linkedin.com/company/liamcrest">
                  <StyledIcon1>
                    <div dangerouslySetInnerHTML={{ __html: svgLinkIn }} />
                  </StyledIcon1>
                </A>
                <A href="https://www.facebook.com/liamcrestcorp?_rdc=2&_rdr">
                  <StyledIcon2>
                    <div dangerouslySetInnerHTML={{ __html: svgFBook }} />
                  </StyledIcon2>
                </A>
                <A href="https://www.behance.net/designers-liamcrest">
                  <StyledIcon3>
                    <div dangerouslySetInnerHTML={{ __html: svg }} />
                  </StyledIcon3>
                </A>
                <A href="https://www.youtube.com/channel/UCVYVnbFIbWQ4a3MfPyMhQHw">
                  <StyledIcon4>
                    <div dangerouslySetInnerHTML={{ __html: svgYoutube }} />
                  </StyledIcon4>
                </A>
              </IconRow>
            </StyledColumn1>
          </StyledB>
        </StyledROw>
        <StyledLine></StyledLine>
        <StyledText>
          <StyledP1>
            Copyright © 2023 by Liam Crest, <span>All rights reserved.</span>
          </StyledP1>
          <StyledP1>Design by Liam Crest</StyledP1>
        </StyledText>
      </StyledContent>
    </StyledMain>
  );
}

export default FooterSection;
