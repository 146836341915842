import styled from "styled-components";
import banner from "../../assets/backgd.png";
import img from "../../assets/fff.png";

export const FeatureTitle = styled.h2`
  text-align: center;
  font-size: clamp(1.3rem, 13vw, 3.1rem);
  line-height: 1.06;
  letter-spacing: 0.4rem;
  margin: auto;
`;




export const Section = styled.section`
  padding: ${({ padding }) => (padding ? padding : "140px 0")};
  margin: ${({ margin }) => (margin ? margin : "")};
width:100%;
  background: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:903px;
  @media screen and (max-width: 768px) {
    padding: ${({ smPadding }) => (smPadding ? smPadding : "65px 0")};
    width: 100%;
   
  }
  @media only screen and (min-width: 320px) and (max-width: 388px) {
    width: 100%;
    height:1529px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    width: 100%;
    height:1377px;
;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    width: 100%;
    height:1377px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 100%;
    height:922px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 100%;
    height:770px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1080px) {
    width: 100%;
    height:785px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
  }
`;
export const FeatureTextWrapper = styled.div`
  margin-bottom: 4rem;
`;
export const ServiceWrapper = styled.div`
////////////
  width: 100%;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    grid-column-gap: 0;
	margin-left:-80px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    grid-column-gap: 0;
    /* margin-left: -13px; */
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    grid-column-gap: 0;
    margin-left: -123px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    grid-column-gap: 0;
    margin-left: -50px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    margin-left: -80px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    margin-left: -167px;
    width: 130%;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1280px) {
    margin-left: -91px;
    width: 115%;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    margin-left: -91px;
    width: 110%;
  }
  @media only screen and (width: 1366px) {
    margin-left: -16px;
  }
`;
export const FeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 4rem;
  padding: 4.3rem 4.8rem 4rem 5rem ;
  background-size: cover;
  background: url(${img});
  background-position: no-repeat;
  margin-left:-4%;
  margin-top:-2.5%;
  width:102%;
  @media only screen and (min-width: 320px) and (max-width: 355px) {
    grid-template-columns: repeat(1, 1fr);
		width:370px;
		position:relative;
		left:-52px;
		padding:8rem;
		grid-column-gap: 0;
		background-size:cover;
		margin-top:-80px;
    margin-left:0;

  }
  @media only screen and (min-width: 356px) and (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
		width:370px;
		position:relative;
		left:-52px;
		padding:8rem;
		grid-column-gap: 0;
		background-size:cover;
		margin-top:-80px;
    margin-left:22px;

  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    grid-template-columns: repeat(1, 1fr);
		width:370px;
		position:relative;
		left:-52px;
		padding:8rem;
		grid-column-gap: 0;
		background-size:cover;
		margin-top:-80px;
    margin-left:30px;

  }
  @media only screen and (min-width: 389px) and (max-width: 440px) {
    grid-template-columns: repeat(1, 1fr);
		width:370px;
		position:relative;
		left:-10px;
		padding:8rem;
		grid-column-gap: 0;
		background-size:cover;
		margin-top:-80px;
		
    margin-left:15px;
 
  }
  @media only screen and (min-width: 441px) and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
		width:370px;
		position:relative;
		left:-10px;
		padding:8rem;
		grid-column-gap: 0;
		background-size:cover;
		margin-top:-80px;
		
    margin-left:31px;
 
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    grid-template-columns: repeat(1, 1fr);
    width: 124%;
    position: relative;
    left: -85px;
    padding: 8rem;
    grid-column-gap: 0;
    background-size: cover;
    margin-top: -80px;
  }
  @media only screen and (min-width: 640px) and (max-width: 660px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
    margin: 0.3rem;
    padding:5.5rem;
    background-size: cover;
    background-position: no-repeat;
    width: 130%;
    margin-top: -8%;
  }
  @media only screen and (min-width: 661px) and (max-width: 693px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
    margin: 0.3rem;
    padding:5.5rem;
    background-size: cover;
    background-position: no-repeat;
    width: 126%;
    margin-top: -8%;
  }
  @media only screen and (min-width: 694px) and (max-width: 722px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
    margin: 0.3rem;
    padding:5.5rem;
    background-size: cover;
    background-position: no-repeat;
    width: 123%;
    margin-top: -8%;
  }
  @media only screen and (min-width: 723px) and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4rem;
    margin: 0.3rem;
    padding:5.5rem;
    background-size: cover;
    background-position: no-repeat;
    width: 118%;
    margin-top: -8%;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    padding: 6.5rem;
    background-size: cover;
    background-position: no-repeat;
    width: 104%;
    margin-left:-20px;
    margin-top: -7%;
  }
  @media only screen and (min-width: 810px) and (max-width: 910px) {
    width: 112%;
    margin-left: 0;
  }
  @media only screen and (min-width: 911px) and (max-width: 960px) {
    width: 112%;
    margin-left: -18px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px)
 {
    padding: 3.3rem 2.8rem 3rem 5rem ;
    width: 85%;
    margin-left: 7.4%;
}
@media only screen and (min-width: 1080px) and (max-width: 1105px)
{
    width: 101%;
    margin-left: -1.6%;
}
@media only screen and (min-width: 1106px) and (max-width: 1279px)
{
  padding: 4.3rem 3.8rem 4rem 5rem ;
    width: 99%;
    margin-left: -1.6%;
}
@media only screen and (width:1280px)
{
  padding: 4.3rem 3rem 4rem 5rem;
  width: 95%;
  margin-left: 33px;
}
  @media only screen and (min-width: 1281px) and (max-width: 1305px) {
    width: 95%;
    margin-left: 33px;
  }
  @media only screen and (min-width: 1306px) and (max-width: 1316px) {
    width: 94%;
    margin-left: 33px;
  }
  @media only screen and (min-width: 1317px) and (max-width: 1337px) {
    width: 93%;
    margin-left: 33px;
  }
  @media only screen and (min-width: 1338px) and (max-width: 1345px) {
    width: 92%;
    margin-left: 33px;
  }
  @media only screen and (min-width: 1346px) and (max-width: 1365px) {
    width: 91.3%;
    margin-left: 33px;
  }
  /* @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 96%;
    margin-left: 33px;
  } */
`;

export const FeatureColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
  padding: 35px;
  border: 0.5px solid rgba(0, 0, 0, 0.17);
  border-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.23)
    )
    1;
  box-shadow: rgba(0, 0, 0, 0.17);
  margin-left: 0px;
  position: relative;
  left: 38px;
  margin-left: 0px;

  top: 30px;
  &:hover {
    background: linear-gradient(
      rgba(168, 254, 255, 0.38),
      rgba(255, 255, 255, 0)
    );
    box-shadow: 0 38px 53px -2px rgba(0, 0, 0, 0.13);
  }

  @media only screen and (min-width: 320px) and (max-width: 340px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
  
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding:5px;
    width: 212px;
    padding: 12px;
    top: 2px;
    margin-left:-40px;
    margin-top: -5px;
    left:40px;
  }
  @media only screen and (min-width: 341px) and (max-width: 357px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
  
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding:5px;
    width: 220px;
    padding: 12px;
    top: 2px;
    margin-left:-40px;
    margin-top: -5px;
    left:40px;
  }
  @media only screen and (min-width: 358px) and (max-width: 362px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
  
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding:5px;
    width: 220px;
    padding: 12px;
    top: 2px;
    margin-left:-40px;
    margin-top: -5px;
    left:46px;
  }
  @media only screen and (min-width: 363px) and (max-width: 388px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
  
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding:5px;
    width: 220px;
    padding: 15px;
    top: 2px;
    left:48px;
    margin-left:-40px;
    margin-top: -5px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
  
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding:5px;
    width: 220px;
      left:46px;
    top: 2px;
    margin-left:-48px;
    margin-top: -5px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.17),
        rgba(255, 255, 255, 0)
      )
      1;
    margin-top: 5px;
    flex-flow: row;
    justify-content: center;
    text-align: center;
    padding: 5px;
    width: 84%;

    top: 2px;
    margin-left: -40px;
    margin-top: -5px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    display: flex;
    padding-bottom: 40px;
    align-items: center;
    padding: 15px;
    margin: 0.2rem;
    border: 0.5px solid rgba(0, 0, 0, 0.17);
    border-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.17)
      )
      1;
    box-shadow: rgba(0, 0, 0, 0.17);
    top: 0;
    left: 15px;
    position: relative;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0.2rem;
    padding: 5px;
    border: 0.5px solid rgba(0, 0, 0, 0.17);
    border-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.17)
      )
      1;
    box-shadow: rgba(0, 0, 0, 0.17);
    position: relative;
    left: 13px;
    margin-left: 0px;
    top:-5px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 5px 0 5px 0;
    margin: 0.2rem;
    border: 0.5px solid rgba(0, 0, 0, 0.17);
    border-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.17)
      )
      1;
    box-shadow: rgba(0, 0, 0, 0.17);
    top: 30px;
    position: relative;
    left: 15px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0.7rem;
    padding: 15px;
    border: 0.5px solid rgba(0, 0, 0, 0.17);
    border-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.17)
      )
      1;
    box-shadow: rgba(0, 0, 0, 0.17);
    margin-left: 0px;
    position: relative;
    left: 22px;
    margin-left: -5px;
    top: 30px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    left: 20px;
    top: 30px;
    margin: 0.1rem;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    left: 20px;
    top: 30px;
    margin: 0.1rem;
   
  }
`;
export const FeatureImageWrapper = styled.div`
  margin-bottom: 0.5rem;
  padding: 10px;
  
  @media only screen and (min-width: 320px) and(max-width:420px) {
    padding:0;
			margin-bottom: 0;
			position:relative;
  }
  @media only screen and (min-width: 481px) and(max-width:639px) {
    padding: 0px;
    margin-bottom: 0;
    position: relative;
  }
  @media only screen and (min-width: 640px) and(max-width:767px) {
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
  }
`;
export const FImage = styled.div`
  margin-top: 30px;
  
  @media only screen and (min-width: 320px) and (max-width: 388px) {
    position:relative;
		left:74px;
		top:-12px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    position:relative;
		left:67px;
		top:-12px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    position: relative;
    left: 80px;
    top: -12px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-top: 10px;
  }
`;
export const FeatureData1 = styled.div`
  margin-left: 10px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    display: inline-flex;
			margin-left:0;
			width:700px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    display: inline-flex;
    margin-left: 0;
    width: 100%;
  }
`;
export const FeatureData2 = styled.div`
  margin-left: 10px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width:130px;
			margin-left:0;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
  
    margin-left: 0;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
  }
`;
export const FeatureName = styled.h3`
  font-weight: 600;
  width:95%;
  font-size: 25px;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  color:#FFFFFF;
  @media only screen and (min-width: 320px) and (max-width: 388px) {
    font-weight: 400;
			width:180px;
			letter-spacing: 0.7px;
			position: relative;
			top:-15px;
			left:70px;
			text-align:left;
			align-items: left;
			font-size:13px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    font-weight: 400;
    width:172px;
			letter-spacing: 0.7px;
			position: relative;
			top:-15px;
			left:61px;
			text-align:left;
			align-items: left;
			font-size:13px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    font-weight: 400;
    width:182px;
    letter-spacing: 0.7px;
    position: relative;
    top: -15px;
    left: 100px;
    text-align: left;
    align-items: left;
    font-size: 14px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 120px;
    font-size: 14px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 120px;
    font-size: 14px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1077px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 1078px) and (max-width: 1280px) {
    font-size: 16px;
  }
`;
export const FeatureText = styled.p`
  width: 134px;
  margin: .5rem 0 auto;
  text-align: center;
  font-size: 0.9rem;
  line-height:21.18px;
  letter-spacing: 0.1px;
  font-size: 18px;
  color: #C1BEC9;
  font-weight: 400;
  font-family: "Inter";
  @media only screen and (min-width: 320px) and (max-width: 340px) {
    position:relative;
		top:5px;
		left:-107px;

		font-size: 10px;
		text-align:left;
		align-items: left;
		width: 199px;

  }
  @media only screen and (min-width: 341px) and (max-width: 388px) {
    position:relative;
		top:5px;
		left:-108px;

		font-size: 10px;
		text-align:left;
		align-items: left;
		width: 199px;

  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    position:relative;
		top:5px;
		left:-109px;
	
		font-size: 10px;
		text-align:left;
		align-items: left;
    width: 199px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    position: relative;
    top: 5px;
    left: -79px;

    font-size: 12px;
    text-align: left;
    align-items: left;
    width: 199px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1280px) {
    font-size: 12px;
  }
`;

export const StyledFeatureText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top:-8%;
  margin-left: -1%;
  @media only screen and (min-width: 1080px) and (max-width: 1280px){
    margin-left: 1%;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-top:-6%;
    margin-left: -2%;

  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-top:-6%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    flex-direction: column;
    margin-top:0;
  }
`;

export const StyledServicesDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 40%;
  @media only screen and (min-width: 321px) and (max-width: 640px) {
    width: 100%;
    text-align: center;
  }
  @media only screen and (min-width:961px) and (max-width:1080px){
    width: 100%;
    margin-left:8%;
  
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 100%;
    margin-left:-8%;
    margin-top:-5%;
  }
  @media only screen and (width:1280px) {
    width: 100%;
    margin-left:-3%;
    margin-top:-4%;
  }
`;

export const StyledServiceText = styled.p`
  color: white;
  margin-left: 13%;
  letter-spacing: 2.5px;
  @media only screen and (min-width: 961px) and (max-width: 1080px){
    margin-left:-8% ;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px){
    margin-left:10% ;
  }
 
  @media only screen and (min-width: 768px) and (max-width: 809px){
    margin-left:15% ;
  }
  @media only screen and (min-width: 641px) and (max-width: 767px){
    margin-left:0;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px){
    margin-left:0 ;
  }

`;

export const StyledServiceHeading = styled.p`
  color: #ffffff;
  font-size: 42px;
  font-family: "Space Grotesk" !important;
  font-weight: 700;
  width: 615px;
  letter-spacing: -0.24px;
  margin-left:5%;
 line-height: 41.6px;
  @media only screen and (min-width: 1366px) and (max-width: 1400px){
    font-size:40px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1365px){
    font-size:38px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size:35px;
    width: 100%;
  }
  @media only screen and (min-width: 961px) and (max-width: 1080px) {
    font-size: 33px;
    width: 109%;
        margin-left:-20%;
  }
  /* @media only screen and (width: 1080px) {
    font-size:29px;
    width: 109%;
    margin-left: 8%;
  } */
  @media only screen and (min-width: 910px) and (max-width: 960px) {
    font-size: 32px;
    width: 105%;
     margin-left:-5%;
  }
  @media only screen and (min-width: 811px) and (max-width: 909px) {
    font-size: 32px;
    width: 100%;
     margin-left:-2%;
  }
  @media only screen and (min-width: 641px) and (max-width: 767px) {
    font-size: 32px;
    width: 100%;
    margin-left:-13%;
  }
  @media only screen and (min-width: 768px) and (max-width: 810px) {
    font-size: 32px;
    width: 100%;
      
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 28px;
    width: 100%;
line-height:31.78px;
}
`;

export const StyledContainer = styled.div`
  padding: 0 6%;
  
  /* @media only screen and (min-width: 1281px) and (max-width: 1365px) {
    padding: 0 10%;
  } */
  @media only screen and (width: 1280px){
    padding:0 6%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 961px) and (max-width: 1080px) {
    padding: 0 10%;
    margin-top: -11%;
  }
  @media only screen and (min-width: 811px) and (max-width: 960px) {
    padding: 0 10%;
    margin-top:-13%;
  }
  @media only screen and (min-width: 768px) and (max-width: 810px) {
    padding: 0 10%;
    margin-top:-5%;
  }
  @media only screen and (min-width: 694px) and (max-width: 767px) {
    padding: 0 10%;
    margin-left:4%;
  }
  @media only screen and (min-width: 641px) and (max-width: 693px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 321px) and (max-width: 640px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 1497px) and (max-width: 1505px) {
    padding: 0 6%;
    width:100%;
  }
  @media only screen and (min-width: 1506px) and (max-width: 1523px) {
    padding:0px 6% 0 5.5%;
    width:100%;
  }
  @media only screen and (min-width: 1524px) and (max-width: 1535px) {
    padding: 0 6%;
    width:100%;
  }
  @media only screen and (min-width: 1536px) and (max-width: 1727px) {
    padding: 0 11%;
    width:100%;
  }
  @media only screen and (min-width: 1728px) and (max-width: 1914px) {
    padding: 0 16%;
  }
  @media only screen and (min-width: 1915px) and (max-width: 2180px) {
    padding: 0 19%;
  }
  @media only screen and (min-width: 2181px) and (max-width: 2409px) {
    padding: 0 23%;
  }

  @media only screen and (min-width: 2410px) and (max-width: 2680px) {
    padding: 0 25%;
  }
`;

export const StyledServicePara = styled.p`
  flex: 1;
  width: 40%;
  margin-left:11%;
  margin-top:1%;
  padding:0 3%;
  color: #ffffff;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 400;
  @media only screen and (min-width: 1366px) and (max-width: 1400px){
  font-size: 16px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1365px) {
    margin-top:2%;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 16px;
    width: 40%;
    padding:0;
    margin-left:6%;
    margin-top:-2.9%;
  }
  @media only screen and (min-width: 961px) and (max-width: 1080px) {
    font-size: 14px;
    width: 40%;
    padding:0;
    margin-left:0;
    margin-top:21px;
  }
  @media only screen and (min-width: 910px) and (max-width: 960px) {
    font-size: 14px;
    width: 40%;
    padding:0;
    margin-left:3.5%;
  }
  @media only screen and (min-width: 810px) and (max-width: 909px) {
    font-size: 14px;
    width: 40%;
    padding:0;
    margin-left:0;
  }
  @media only screen and (min-width: 769px) and (max-width: 809px) {
    font-size: 12px;
    width: 40%;
    padding:0;
    margin-left:0;
    margin-top:-7%;
  }
  @media only screen and (min-width: 641px) and (max-width: 768px) {
    font-size: 12px;
    width: 40%;
    padding:0;
    margin-left:0;
  }
  @media only screen and (min-width: 321px) and (max-width: 640px) {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-left:0;
  }
`;
export const LineSec = styled.div`
  width: 24.9px;
  height: 1.5px;
  background-color: #ef991b;
  margin-top: 0px;
  position: relative;
    top: 12px;
    left: 6%;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    display:none;
  }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
  display:none;
  }
  @media only screen and (min-width: 641px) and (max-width: 767px) {
    margin-top: -20px;
    width: 16.78px;
    margin-left: -51px;
    height: 0.85px;
    top:9px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-top: -20px;
    width: 16.78px;
    margin-left: -1px;
    height: 0.85px;
    top:9px;
  }
  @media only screen and (min-width: 810px) and (max-width: 909px) {
    margin-left: -21px;
    margin-top: 0px;
    top:9px;
  }
  @media only screen and (min-width: 910px) and (max-width: 960px) {
    margin-left: -45px;
    margin-top: 0px;
    top:9px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1080px) {
    margin-left: -100px;
    margin-top: 0px;
    top:9px;
  }

  @media only screen and (min-width: 1081px) and (max-width: 1279px) {
    width: 20.93px;
    height: 1.26px;
    margin-left: -1px;
    margin-top: 0px;
    top:9px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    margin-top: 0px;
    width: 24.9px;
    margin-left: -1px;
    top:9px;
  }
`;

export const TextWrapper= styled.div`


`;