import React, { useEffect, useRef, useState } from "react";
import {
  Overlay,
  PauseButton,
  PlayButton,
  StyledA,
  StyledDiv,
  StyledDiv1,
  StyledH3,
  StyledLine,
  StyledMain,
  StyledP,
  StyledP1,
  StyledText,
  StyledVideo,
  StyledVideoContainer,
} from "./styles";

function VideoSection() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOverlayHidden, setIsOverlayHidden] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video.paused || video.ended) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  return (
    <StyledMain className="showBackground" id="About">
      <StyledA className="showBackground">
        <StyledDiv1>
          <StyledLine></StyledLine>
          <StyledP1>About us</StyledP1>
        </StyledDiv1>
        <StyledH3>
          Liam Crest: Crafting Digital Brilliance, One Line of Code at a Time!
        </StyledH3>
        <StyledP>
          At Liam Crest, we're not just developers; we're architects of digital
          innovation. With a rich background spanning years of hands-on
          experience in web and software development, our team combines
          technical expertise with creative vision to craft cutting-edge
          solutions tailored to your needs. Whether it's building responsive
          websites that captivate audiences or engineering robust software
          solutions that streamline operations, Liam Crest stands at the
          forefront of technology, ready to turn your ideas into reality. With a
          track record of delivering excellence, we pride ourselves on our
          commitment to quality, efficiency, and client satisfaction, making us
          the trusted partner for all your digital ventures.
        </StyledP>
      </StyledA>
      <StyledVideoContainer>
        <StyledVideo
          ref={videoRef}
          src="./liamcrest.mp4"
          controls
          onPlay={handlePlay}
          onPause={handlePause}
          poster="./thumbnail.png"
          onMouseEnter={() => setIsOverlayHidden(false)}
          onMouseLeave={() => setIsOverlayHidden(true)}
        ></StyledVideo>
        <Overlay
          onMouseEnter={() => setIsOverlayHidden(false)}
          onMouseLeave={() => setIsOverlayHidden(true)}
          isHidden={!isOverlayHidden}
          onClick={togglePlayPause}
        >
          {isPlaying ? <PauseButton /> : <PlayButton />}
        </Overlay>
      </StyledVideoContainer>
    </StyledMain>
  );
}

export default VideoSection;
