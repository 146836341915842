import React, { useState, useEffect } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink,
  MobileIcon,
  Button2,
  Button3,
} from "./styles";
import LogoSrc from "../../assets/logo.png";
import { IconContext } from "react-icons/lib";
import { FaBars, FaTimes } from "react-icons/fa";
import ContactFormPopup from "../ContactPopUp/index";
const Header = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isFormOpen, setFormOpen] = useState(false);

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      closeMobileMenu();
    }
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#03375E" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" src={LogoSrc} />
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks
                  onClick={() => scrollToSection("Home")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={() => scrollToSection("About")}
                  style={{ cursor: "pointer" }}
                >
                  About Us
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  onClick={() => scrollToSection("Work")}
                  style={{ cursor: "pointer" }}
                >
                  Work
                </NavLinks>
              </NavItem>
            </NavMenu>

            <NavItemBtn>
              <NavBtnLink to="/">
                <Button2 primary onClick={openForm}>
                  CONNECT
                </Button2>

                {isFormOpen && <ContactFormPopup onClose={closeForm} />}
              </NavBtnLink>
            </NavItemBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Header;
