import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 160px 0;
  background: #16205d;
  height: 1190px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: 1396px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    width: 100%;
    height: 1398px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 100%;
    height: 867px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 100%;
    height: 899px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 100%;
    height: 958px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 100%;
    height: 958px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 100%;
    height: 1044px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 100%;

  padding-top: 0;
  position: relative;
  top: -116px;
  padding-bottom: 20px;
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-left: 24px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;
export const TextLeft=styled.div`
@media only screen  and (min-width:320px) and (max-width:480px){
  display: flex;
  align-items: center;
  justify-content: center;
}
`;
export const LineSec = styled.div`
  width: 24.9px;
  height: 1.5px;
  background-color: #ef991b;
  margin-top: 0px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-top: -22px;
    width: 13.78px;
    margin-left:41px;
    height: 0.85px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    margin-top: -20px;
    width: 13.78px;
    margin-left: 127px;
    height: 0.85px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-top: -20px;
    width: 16.78px;
    margin-left: 180px;
    height: 0.85px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-top: -20px;
    width: 16.78px;
    margin-left: 180px;
    height: 0.85px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    margin-left: 20px;
    margin-top: 0px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    margin-left: 100px;
    margin-top: 0px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 20.93px;
    height: 1.26px;
    margin-left: 40px;
    margin-top: 0px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    margin-top: 0px;
    width: 24.9px;
    margin-left: 60px;
  }
`;

export const LightText = styled.div`
  line-height: 25.04px;
  font-family: "Montserrat";
  font-size: 16px;
  color: #ffffff;
  width: 151px;
  letter-spacing: 2.7px;
  font-weight: 500;
  position: relative;
  top: -12px;
  left: 45px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-left: 12px;
    top: -12px;
    font-size: 14px;
    width: 60%;
    left: 0;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    margin-left: 153px;
    top: -12px;
    font-size: 14px;
    width: 60%;
    left: 0;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    left: 0;
    margin-left: 220px;
    top: -12px;
    font-size: 14px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    left: 0;
    margin-left: 220px;
    top: -12px;
    font-size: 14px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    margin-left: 60px;
    left: 0;
    width: 90%;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    margin-left: 100px;
  }

  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    margin-left: 80px;
    font-size: 14px;
    left: 0;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    margin-left: 60px;
    font-size: 16px;
  }
`;
export const Heading3 = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 32px;
  width: 800px;
  text-align: left;
  line-height: 39.04px;
  font-family: "Montserrat";
  font-weight: 700;
  color: #ffffff;
  & span {
    color: #ef991b;
    font-family: "Space Grotesk";
    font-size: 32px;
    font-weight: 700;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 20px;
    line-height: 26.6px;
    width: 100%;
    text-align: center;

    & span {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    font-size: 20px;
    line-height: 26.6px;
    width: 100%;
    text-align: center;

    & span {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 28px;
    line-height: 29.12px;
    width: 100%;
    text-align: center;

    & span {
      font-size: 28px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 28px;
    line-height: 29.12px;
    width: 100%;
    text-align: center;

    & span {
      font-size: 28px;
    }
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 24px;
    line-height: 29.12px;
    width: 100%;
    text-align: left;
    margin-left: 20px;

    & span {
      font-size: 24px;
    }
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 24px;
    line-height: 29.12px;
    width: 81%;
    text-align: left;
    margin-left: 100px;

    & span {
      font-size: 24px;
    }
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    margin-left: 40px;
    font-size: 30px;
    width: 85%;
    & span {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    margin-left: 60px;
    width: 692px;
    font-size: 32px;
    & span {
      font-size: 32px;
    }
  }
`;

export const ButtonRow5 = styled.div`
  display: flex;
  gap: 3px;
  padding: 8px;
  font-family: "Montserrat";
  margin-top: 80px;
  letter-spacing: 1.3px;
  background-color: #f3f3f3;
  height: 72px;
  width: 796px;
  border-radius: 100px;
  @media only screen and (min-width: 320px) and (max-width: 370px) {
    background: none;
    margin-left: -20px;
    height: 28px;
    padding: 0;
    width: 100%;
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    background: none;
    margin-left: -13px;
    height: 28px;
    padding: 0;
    width: 100%;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    background: none;
    margin-left: -13px;
    height: 28px;
    padding: 0;
    width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 538px) {
    background: none;

    height: 28px;
    padding: 0;
    width: 73%;
    margin-left:20px;
  }
  @media only screen and (min-width: 539px) and (max-width: 545px) {
    background: none;
    height: 28px;
    padding: 0;
    width: 65%;
    margin-left:10px;
  }
  @media only screen and (min-width: 546px) and (max-width: 639px) {
    background: none;
    margin-left: 50px;
    height: 28px;
    padding: 0;
    width: 62%;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 78%;
    height: 59px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 580px;
    height: 59px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 590px;
    height: 59px;
    margin-left: 20px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 590px;
    height: 59px;
    margin-left: 100px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 677px;
    height: 66px;
    margin-left: 40px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 677px;
    padding: 8px;
    height: 72px;
    margin-left: 60px;
  }
`;

export const Button21 = styled.button`
  cursor: pointer;
  width: 168px;
  height: 56px;
  padding: 16px 24px;
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  font-size: 16px;
  background-color: ${(props) => (props.isActive ? "#F5AA3B" : "#F3F3F3")};
  outline: none;
  border-radius: 100px;
  border: none;
  color: ${(props) => (props.isActive ? "#000000" : "rgba(109, 112, 121, 1)")};
  font-family: "Montserrat";
  transition: background-color 0.3s ease-in-out;
  @media only screen and (min-width: 320px) and (max-width: 370px) {
    font-family: "Montserrat";
    margin-left: 0px;
    margin-top: -50px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 130px;
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    font-family: "Montserrat";
    margin-left: 10px;
    margin-top: -50px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 44%;
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    font-family: "Montserrat";
    margin-left:15px;
    margin-top: -50px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 44%;
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    font-family: "Montserrat";
    margin-left: 10px;
    margin-top: -50px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 130px;
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    font-family: "Montserrat";
    margin-left: 10px;
    margin-top: -50px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 130px;
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    width: 128px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    width: 128px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 143px;
    height: 56px;
    font-size: 14px;
    padding: 0;
    margin-top: -2.7px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 158px;
    font-size: 16px;
    padding: 0;
    height: 56px;
  }
`;

export const Button22 = styled.button`
  cursor: pointer;
  width: 198px;
  height: 56px;
  padding: 16px 24px;
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  font-size: 16px;
  background-color: ${(props) => (props.isActive ? "#F5AA3B" : "#F3F3F3")};
  outline: none;
  border-radius: 100px;
  border: none;
  color: ${(props) => (props.isActive ? "#000000" : "rgba(109, 112, 121, 1)")};
  font-family: "Montserrat";
  transition: background-color 0.3s ease-in-out;
  @media only screen and (min-width: 320px) and (max-width: 370px) {
    margin-top: -15px;
    font-family: "Montserrat";
    margin-left: -120px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 158px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    margin-top: -15px;
    font-family: "Montserrat";
    margin-left: -120px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 59%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-top: -15px;
    font-family: "Montserrat";
    margin-left: -120px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 59%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    margin-top: -15px;
    font-family: "Montserrat";
    margin-left: -120px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 158px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    margin-top: -15px;
    font-family: "Montserrat";
    margin-left: -120px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 158px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    width: 148px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    width: 148px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 158px;
    height: 56px;
    font-size: 14px;
    padding: 0;
    padding: 0;
    text-align: center;
    margin-top: -2.7px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 190px;
    font-size: 16px;
    height: 56px;

    padding: 0;
  }
`;

export const Button23 = styled.button`
  cursor: pointer;
  width: 262px;
  height: 56px;
  padding: 16px 24px;
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  font-size: 16px;
  background-color: ${(props) => (props.isActive ? "#F5AA3B" : "#F3F3F3")};
  outline: none;
  border-radius: 100px;
  border: none;
  color: ${(props) => (props.isActive ? "#000000" : "rgba(109, 112, 121, 1)")};
  font-family: "Montserrat";
  transition: background-color 0.3s ease-in-out;
  @media only screen and (min-width: 320px) and (max-width: 370px) {
    margin-top: -50px;
    font-family: "Montserrat";
    margin-left: -30px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 67%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    margin-top: -50px;
    font-family: "Montserrat";
    margin-left: -45px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 67%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-top: -50px;
    font-family: "Montserrat";
    margin-left: -57px;;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 67%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    margin-top: -50px;
    font-family: "Montserrat";
    margin-left: -40px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 185px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    margin-top: -50px;
    font-family: "Montserrat";
    margin-left: -40px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 185px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    width: 205px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 12px;
    width: 205px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 205px;
    height: 56px;
    font-size: 14px;
    padding: 0;
    padding: 0;
    text-align: center;
    margin-top: -2.7px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 252px;
    font-size: 16px;
    height: 56px;
    padding: 0;
  }
`;

export const Button24 = styled.button`
  cursor: pointer;
  width: 160px;
  height: 56px;
  padding: 16px 24px;
  font-weight: ${(props) => (props.isActive ? "600" : "500")};
  font-size: 16px;
  background-color: ${(props) => (props.isActive ? "#F5AA3B" : "#F3F3F3")};
  outline: none;
  border-radius: 100px;
  border: none;
  color: ${(props) => (props.isActive ? "#000000" : "rgba(109, 112, 121, 1)")};
  font-family: "Montserrat";
  transition: background-color 0.3s ease-in-out;
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -138px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 46%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 360px) and (max-width: 370px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -162px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 46%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -138px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 46%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -141px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 46%;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -137px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 108px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    margin-top: -14px;
    font-family: "Montserrat";
    margin-left: -137px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    width: 108px;
    height: 28px;
    border: 1px solid rgba(229, 229, 229, 0.29);
    background-color: ${(props) =>
      props.isActive ? "#F5AA3B" : "rgba(250, 250, 250, 0.32)"};
    color: ${(props) =>
      props.isActive ? "#000000" : "rgba(230, 230, 230, 1)"};
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    width: 132px;
    height: 47px;
    padding: 0px;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    width: 132px;
    height: 47px;
    padding: 0px;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 12px;
    width: 178px;
    height: 47px;
    padding: 0;
    margin-top: -2.5px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 166px;
    height: 56px;
    font-size: 14px;

    padding: 0;
    text-align: center;
    margin-top: -2.7px;
    margin-left: -1px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 160px;
    font-size: 16px;
    height: 56px;
    padding: 0;
  }
`;
export const CardDescription = styled.div`
  width: 626px;
  height: 629px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 288px;
    height: 367px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    width: 288px;
    height: 367px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 274px;
    height: 367px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 360px;
    height: 439px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 360px;
    height: 439px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 521px;
    height: 629px;
    border-radius: 32px;
  }
`;

export const TwoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;
  height: 100%;
  width: 100%;
  margin-left: -5px;
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    margin-top: 50px;
    margin-left: -10px;
    flex-direction: column;
  }
    @media only screen and (min-width: 360px) and (max-width: 370px) {
    margin-top: 50px;
    margin-left: 0px;
    flex-direction: column;
  }
  @media only screen and (min-width: 371px) and (max-width: 388px) {
    margin-top: 50px;
    margin-left: 6px;
    flex-direction: column;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-top: 50px;
    margin-left: 20px;
    flex-direction: column;
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    margin-top: 50px;
    margin-left: 50px;
    flex-direction: column;
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    margin-top: 50px;
    margin-left: 81px;
    flex-direction: column;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-left: -10px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-left: 0px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 360px;
    height: 439px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 360px;
    height: 439px;
    margin-left: 100px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 437px;
    height: 534px;
    border-radius: 32px;
    margin-left: 40px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 521px;
    height: 629px;
    border-radius: 32px;
    margin-left: 60px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1400px) {
    margin-left: -14px;
  }
`;

export const FirstCard = styled.div`
  flex: 1;
  border-radius: 32px;
  padding: 32px 14px 14px 14px;


  background-color: #93d7d8;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    border-radius: 22px;
    padding: 32px 14px 14px 14px;
    margin-left: -8px;
    margin-top: -23px;
  }
  @media only screen and (min-width: 481px) and (max-width: 540px) {
    border-radius: 22px;
    padding: 32px 14px 14px 14px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 541px) and (max-width: 639px) {
    border-radius: 22px;
    padding: 32px 14px 14px 14px;
    margin-left: 0px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    border-radius: 22px;
    padding: 32px 14px 14px 14px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    border-radius: 22px;
    padding: 32px 14px 14px 14px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 360px;
    height: 439px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 360px;
    height: 439px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 437px;
    height: 534px;
    border-radius: 32px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 521px;
    height: 629px;
    border-radius: 32px;
  }
`;

export const SecondCard = styled.div`
  flex: 1;
  padding: 32px 14px 14px 14px;
  background-color: #93d7d8;
  margin-left: 40px;
  border-radius: 32px;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-left: 0;
    margin-top: 20px;
    padding: 32px 14px 14px 14px;
    border-radius: 22px;
    margin-left: -8px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    margin-left: 0;
    margin-top: 20px;
    padding: 32px 14px 14px 14px;
    border-radius: 22px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-left: 20px;
    border-radius: 24px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-left: 20px;
    border-radius: 24px;
    width: 294px;
    height: 367px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 360px;
    height: 439px;
    border-radius: 24px;
    margin-left: 20px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 360px;
    height: 439px;
    border-radius: 24px;
    margin-left: 20px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 437px;
    height: 534px;
    border-radius: 32px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 521px;
    height: 629px;
    border-radius: 32px;
  }
`;

export const CardImage = styled.img`
  height: auto;
  position: relative;
  top: -20px;
  border-radius: 15px;
  &:hover {
    filter: grayscale(100%);
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 273px;
    height: 262px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    width: 273px;
    height: 262px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 279px;
    height: 262px;
    border-radius: 20px;
    margin-left: -6px;
    margin-top: -4px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 279px;
    height: 262px;
    border-radius: 22px;
    margin-left: -8px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 341px;
    height: 322px;
    border-radius: 20px;
    margin-left: -5px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 341px;
    height: 322px;
    border-radius: 20px;
    margin-left: -5px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 414px;
    height: 390px;
    border-radius: 20px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 492px;
    height: 465px;
    border-radius: 20px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 24px;
  margin-top: 20px;
  font-weight: 700;
  margin-left: 20px;
  font-family: "Montserrat";
  color: #16205d;
  line-height: 40px;
  position: relative;
  top: -20px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
   
    margin-top: 0px;
    font-size: 12px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    font-size: 12px;
    margin-top: 0px;
    width: 252px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 27px;
    margin-top: 10px;
    margin-left: 10px;
    width: 316px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    line-height: 33px;
    margin-top: 0px;
    width: 316px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 316px;
    font-size: 16px;
    margin-top: 0px;
    margin-left: 18px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 344px;
    font-size: 16px;
    margin-top: 0px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 438px;
    font-size: 20px;
    line-height: 40px;
    margin-top: 10px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 355px;
    font-size: 20px;
    line-height: 33px;
  }
`;
export const CardDescription1 = styled.p`
  font-size: 16px;
  margin-left: 20px;
  color: #16205d;
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 27.5px;
  position: relative;
  top: -20px;
  margin-top: 10px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: -10px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    top: -25px;
  }

  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 14px;
    line-height: 18.5px;
    margin-top: 0px;
    top: -25px;
    width: 325px;
    height: 38px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    font-size: 14px;
    line-height: 18.5px;
    margin-top: 0px;
    top: -15px;
    width: 325px;
    height: 38px;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 385px;
    height: 45px;
    font-size: 16px;
    line-height: 22.5px;
  }
`;

export const ProjectDetail = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
  }
`;

export const Customhr = styled.hr`
  border: none;
  border-top: 1px solid #5d5d63;
  position: relative;
  left: -25px;
  top: -106px;
  width: 1338px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-top: 275px;
    left: 0px;
    top: -17px;
    width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    margin-top: 313px;
    left: 50px;
    top: -39px;
    width: 350px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    margin-top: -60px;
    left: -10px;
    width: 606px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    margin-top: -30px;
    left: 30px;
    width: 606px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    left: 0px;
    width: 745px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    left: 100px;
    width: 745px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 905px;
    margin-top: -124px;
    left: 40px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 1077px;
    margin-top: -10px;
    left: 60px;
  }
  @media only screen and (width: 1366px) {
    margin-left: -10px;
  }
`;
export const Customhr1 = styled.hr`
  display: none;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    border-top: 1px solid #5d5d63 !important;
    display: flex;
    left: 0px;
    position: relative;
    width: 100%;
    top: 108px;
    color: rgba(255, 255, 255, 0.2) !important;
    border-left: none;
    border-bottom: none;
    border-right: none;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    border-top: 1px solid #5d5d63 !important;
    display: flex;
    left: 50px;
    position: relative;
    width: 350px;
    top: 98px;
    color: rgba(255, 255, 255, 0.2) !important;
    border-left: none;
    border-bottom: none;
    border-right: none;
  }
`;
export const ProjectDetailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 150px;
  margin-top: -9%;
  margin-left: -22px;
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-left: -25px;

    > :nth-child(1) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 45px;
    }
    > :nth-child(2) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
    > :nth-child(3) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 45px;
    }
    > :nth-child(4) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 388px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-left: 17px;

    > :nth-child(1) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 35px;
    }
    > :nth-child(2) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
    > :nth-child(3) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 45px;
    }
    > :nth-child(4) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-left: 17px;

    > :nth-child(1) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 33px;
    }
    > :nth-child(2) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
    > :nth-child(3) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 33px;
    }
    > :nth-child(4) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 25px;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-left: -5px;
    > :nth-child(2) {
      position: relative;
      left: -65px;
    }
    > :nth-child(3) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: 15px;
    }
    > :nth-child(4) {
      /* Adjust styles for the second column here */
      /* For example, move the second column to the right */
      position: relative;
      left: -31px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    gap: 0px;
    margin-left: -10px;
    margin-top: -117px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    gap: 0px;
    margin-left: 40px;
    margin-top: -117px;
  }

  @media only screen and (min-width: 810px) and (max-width: 960px) {
    margin-left: 10px;
    gap: 30px;
    margin-top: -117px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    margin-left: 110px;
    gap: 30px;
    margin-top: -117px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    gap: 40px;
    margin-top: -117px;
    margin-left: 38px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    gap: 50px;
    margin-left: 60px;
    margin-top: -117px;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1400px) {
    gap: 120px;
    margin-left: 0px;
  }
`;

export const ProjectDetailItem = styled.div`
  display: flex;
  @media only screen and (min-width: 320px) and (max-width: 388px) {
    margin-left: 10px;
    width: 68%;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-left: 20px;
    width: 50%;
  }
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
  }
  @media only screen and (min-width: 1079px) and (max-width: 1279px) {
  }
`;
export const ProjectNumber = styled.p`
  font-weight: 600;
  font-size: 40px;
  margin-top: 40px;
  font-family: "Montserrat";
  color: #ef991b;
  position: relative;
  top: 40px;
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    margin-top: 33px;
    font-size: 18px;
    margin-left: -41px;
  }
  @media only screen and (min-width: 360px) and (max-width: 388px) {
    margin-top: 27px;
    font-size: 24px;
    margin-left: -60px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    margin-top: 28px;
    font-size: 24px;
    margin-left: -63px;
  }
 
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    font-size: 24px;
    margin-left: 33px;
    margin-top: 31px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    font-size: 32px;
    line-height: 35.36px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    font-size: 40px;
    line-height: 44.2px;
  }
`;
export const ProjectImg = styled.div`
  width: 27.5px;
  height: 33px;
  color: rgba(255, 255, 255, 0.71);
  position: relative;
  top: 40px;
  left: 20px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    top: 25px;
    width: 16.12px;
    left: 5px;
  }
  @media only screen and (min-width: 481px) and (max-width: 639px) {
    top: 25px;
    width: 18.12px;
    left: 5px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    width: 18.12px;
    height: 15.1px;
    left: 8px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    width: 18.12px;
    height: 15.1px;
    left: 8px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    width: 22.23px;
    height: 22.23px;
    left: 8px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    width: 22.23px;
    height: 22.23px;
    left: 8px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    width: 27px;
    height: 27px;
    left: 14px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width: 33px;
    height: 33px;
    left: 18px;
    line-height: 13.05px;
  }
`;
export const ProjectDescription = styled.p`
  font-size: 15px;
  color: #d0d0d0;
  letter-spacing: 2.16px; //13.5%;
  font-weight: 500;
  font-family: "Montserrat";
  position: relative;
  top: 40px;
  left: -20px;
  margin-top: 45px;
  width: 110px;
  height: 46px;
  margin-left: 10px;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    top: 30px;
    left: -20px;
    font-size: 10px;
    align-items: center;
    margin-top: 40px;
    width: 100%;
    font-size: 10px;
  }
  @media only screen and (min-width: 360px) and (max-width: 388px) {
    top: 30px;
    left: -25px;
    font-size: 10px;
    align-items: center;
    margin-top: 40px;
    width: 100%;
    font-size: 10px;
  }
  @media only screen and (min-width: 389px) and (max-width: 480px) {
    top: 30px;
    left: -25px;
    font-size: 10px;
    align-items: center;
    margin-top: 40px;
    width: 100%;
    font-size: 10px;
  }

  @media only screen and (min-width: 481px) and (max-width: 639px) {
    top: 30px;
    left: -20px;
    font-size: 10px;
    align-items: center;
    margin-top: 40px;
    width: 70px;
  }
  @media only screen and (min-width: 640px) and (max-width: 767px) {
    font-size: 10px;
    letter-spacing: 2.5px;
    width: 82px;
    line-height: 13.05px;
  }
  @media only screen and (min-width: 768px) and (max-width: 809px) {
    font-size: 10px;
    letter-spacing: 2.5px;
    width: 82px;

    line-height: 13.05px;
  }
  @media only screen and (min-width: 810px) and (max-width: 960px) {
    font-size: 10px;
    width: 83.75px;
    letter-spacing: 2.5px;
    line-height: 13.05px;
    font-family: "Montserrat";
  }
  @media only screen and (min-width: 961px) and (max-width: 1079px) {
    font-size: 10px;
    width: 83.75px;
    letter-spacing: 2.5px;
    line-height: 13.05px;
    font-family: "Montserrat";
  }
  @media only screen and (min-width: 1080px) and (max-width: 1279px) {
    font-size: 12px;
    line-height: 15.66px;
    letter-spacing: 2.5px;
    width: 101.71px;
    height: 38.66px;
  }
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    font-size: 15px;
    line-height: 23.48px;
    letter-spacing: 2.5px;
    width: 121px;
    height: 46px;
  }
`;
