import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {DetailInfo,DetailDiv,DetailForm,DetailForm1,DetailForm2,DetailForm3,Label,InputField,TextArea,
DetailHeading,DetailH,DetailItem,DetailFormHeading,Btn,Button1,Button,Btn1} from './style';
import axios from 'axios'; 

export const DetailForms = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [planType, setPlanType] = useState('');
  const [packageType, setPackageType] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const planTypeFromQuery = searchParams.get('planType');
    const packageTypeFromQuery = searchParams.get('packageType');
    
    if (planTypeFromQuery) {
      setPlanType(planTypeFromQuery);
    }
  
    // Set packageType in the state
    if (packageTypeFromQuery) {
      setPackageType(packageTypeFromQuery);
    }
  }, [location.search]);
  const HandleCancelButton = () => {
    navigate(`/`);
  }


  const [formData, setFormData] = useState({
    website: "development-plans",
    name: "",
    email: "",
    plan_category:"",
    plan_type:"",
    message: ""
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSend = async () => {
    try {
      const requestData = {
        ...formData,
        plan_category: planType,
        plan_type: packageType,
      };
      // console.log("Sending data:", requestData);

      const response = await axios.post(
        "https://lmsbackend.liamcrest.com/api/register_query",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      console.log("Response:", response);

      if (response.status === 200) {
        setSuccessMessage("Form submitted successfully!");
        setTimeout(() => {
          setSuccessMessage(null);
          navigate('/');
        }, 4000);
      } else {
        console.error("Form submission failed.");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  

  return (
    <DetailInfo>
        <DetailDiv>
          <DetailItem>
           <DetailHeading>
              <DetailH>Detail About Plan</DetailH>
           </DetailHeading>
           <DetailFormHeading>
            <DetailForm>
                <Label  htmlFor="inputField">Name:</Label>
                <InputField
                  id="name"
                placeholder="Name"
                onChange={handleInputChange}
                value={formData.name}
                 />
            </DetailForm>
            <DetailForm1>
                <Label  htmlFor="inputField">Email:</Label>
                <InputField
                   id="email"
                placeholder="Email"
                onChange={handleInputChange}
                value={formData.email}
                />
            </DetailForm1>
            <DetailForm2>
                <Label  htmlFor="inputField" >Plan Category:</Label>
                <InputField
                   id="plan"
                placeholder="Plan Category"
                value={planType}
                onChange={handleInputChange}
                 readOnly 
                />
            </DetailForm2>
            <DetailForm2>
                <Label  htmlFor="inputField" >Plan Type:</Label>
                <InputField
                   id="plan"
                   placeholder="Plan Type"
                  value={packageType}
                  onChange={handleInputChange}
                  readOnly
                
                />
            </DetailForm2>
           <DetailForm3>
                <Label  htmlFor="inputField">Message:</Label>         
              <TextArea
                id="message"
                placeholder="Your Message"
                onChange={handleInputChange}
                value={formData.message}
                />
            </DetailForm3>
            </DetailFormHeading>
            {successMessage && (
        <div style={{ color: 'green', marginTop: '5%' ,fontSize:'16px' }}>
          {successMessage}
        </div>
      )}
            <Btn>
                <Btn1><Button onClick={HandleCancelButton}>Cancel</Button></Btn1>
              <Button1 onClick={handleSend}>Send Request</Button1>
            </Btn>
        
            </DetailItem>
        </DetailDiv>
    </DetailInfo>
  )
}

export default DetailForms