import React from "react";
import {
  Design,
  Strategy,
  StyledCarousel,
  StyledH1,
  StyledH2,
  StyledImg,
  StyledLine,
  StyledMain,
  StyledP,
  StyledText,
} from "./styles";
import { Carousel } from "react-responsive-carousel";
function SectionFour() {
  return (
    <StyledMain>
      <StyledCarousel>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={false}
          emulateTouch={true}
          infiniteLoop={true}
          selectedItem={0}
          interval={5000}
          transitionTime={800}
          stopOnHover={true}
        >
          <Strategy>
            <StyledH1>Strategy</StyledH1>
            <StyledImg src="./image1.png" alt="img" />
            <StyledText>
              <StyledP>
                Crafting Your <span>Path</span> to Success
              </StyledP>
              <StyledLine>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="106"
                  height="9"
                  viewBox="0 0 106 9"
                  fill="none"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="106"
                    y2="1"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                  <line
                    x1="5"
                    y1="8"
                    x2="101"
                    y2="8"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                </svg>
              </StyledLine>
            </StyledText>
          </Strategy>
          <Design>
            <StyledH2>Design</StyledH2>
            <StyledImg src="./image2.png" alt="img" />
            <StyledText>
              <StyledP>
                Transforming Ideas into <span>Visually Stunning</span> Concepts
              </StyledP>
              <StyledLine>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="106"
                  height="9"
                  viewBox="0 0 106 9"
                  fill="none"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="106"
                    y2="1"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                  <line
                    x1="5"
                    y1="8"
                    x2="101"
                    y2="8"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                </svg>
              </StyledLine>
            </StyledText>
          </Design>
          <Strategy>
            <StyledH1>Develop</StyledH1>
            <StyledImg src="./image3.png" alt="img" />
            <StyledText>
              <StyledP>
                Bringing Your <span>Vision</span> to Life
              </StyledP>
              <StyledLine>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="106"
                  height="9"
                  viewBox="0 0 106 9"
                  fill="none"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="106"
                    y2="1"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                  <line
                    x1="5"
                    y1="8"
                    x2="101"
                    y2="8"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                </svg>
              </StyledLine>
            </StyledText>
          </Strategy>
          <Design>
            <StyledH2>Launch</StyledH2>
            <StyledImg src="./image4.png" alt="img" />
            <StyledText>
              <StyledP>
                Unveil Your <span>Success</span> to the World
              </StyledP>
              <StyledLine>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="106"
                  height="9"
                  viewBox="0 0 106 9"
                  fill="none"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="106"
                    y2="1"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                  <line
                    x1="5"
                    y1="8"
                    x2="101"
                    y2="8"
                    stroke="#16205D"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-dasharray="2 4"
                  />
                </svg>
              </StyledLine>
            </StyledText>
          </Design>
        </Carousel>
      </StyledCarousel>
    </StyledMain>
  );
}

export default SectionFour;
