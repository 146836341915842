import React from 'react';
import serv from '../assets/service1.png'
import data from '../assets/data.png';
import mob from '../assets/mob.png'
import sam from '../assets/sm.png'
import ui from '../assets/ui.png'
import db from '../assets/db.png'
import cs from '../assets/cs.png'
import seo from '../assets/seo.png'

const Service1 = () => <img src={serv} alt="Mobile Icon" width="49" height="49" />;
const Service2 = () => <img src={mob} alt="Mobile Icon" width="49" height="49" />;
const Service3 =() => <img src={data} alt="Data Analysis" width="49" height="49"/>
const Service4 = () => <img src={sam} alt="Mobile Icon" width="49" height="49" />;
const Service5 = () => <img src={ui} alt="Mobile Icon" width="49" height="49" />;
const Service6 =() => <img src={db} alt="Data Analysis" width="49" height="49"/>
const Service7 = () => <img src={cs} alt="Mobile Icon" width="49" height="49" />;
const Service8 =() => <img src={seo} alt="Data Analysis" width="49" height="49"/>



export const ServicesData = [
  {
    name: "Product Development",
    description: "Unlocking Innovation and Success",
    icon: <Service1 />,
    imgClass: "one",
  },
  {
    name: "App Development",
    description: "Innovative Apps for Digital World",
    icon: <Service2 />,
    imgClass: "two",
  },
  {
    name: "Data Analysis",
    description: "Uncover Insights from Your Data",
    icon: <Service3 />,
    imgClass: "three",
  },
  {
    name: "Server Management",
    description: "Reliable Server Solutions",
    icon: <Service4 />,
    imgClass: "four",
  },
  {
    name: "UI/UX Designing",
    description: "Create User-Centric Experiences",
    icon: <Service5 />,
    imgClass: "five",
  },
  {
    name: "Database Management",
    description: "Efficient Data Handling",
    icon: <Service6 />,
    imgClass: "six",
  },
  {
    name: "Cyber Security",
    description: "Protect Your Digital Assets",
    icon: <Service7 />,
    imgClass: "five",
  },
  {
    name: "SEO Management",
    description: "Boost Your Online Visibility",
    icon: <Service8 />,
    imgClass: "six",
  },
];