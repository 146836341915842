import styled from "styled-components";

export const StyledMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height:650px;
  }
  @media only screen and (min-width: 1537px) and (max-width: 2800px) {
    height:650px;
  }
`;

export const Strategy = styled.div`
  width: 100%;
  height: 1055px;
  background: #fff9ef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1537px) and (max-width: 2800px) {
    height:650px
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height:650px
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 800px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 750px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 720px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 600px;
  }
`;
export const Design = styled.div`
  width: 100%;
  height: 1055px;
  background: #f7f8fc;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  @media only screen and (min-width: 1537px) and (max-width: 2800px) {
    height:650px
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 650px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 800px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 750px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 720px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 600px;
  }
`;
export const StyledH1 = styled.h1`
  width: 90%;
  margin-top: 3%;
  color: #f8f0e3;
  text-align: center;
  font-family: Poppins;
  font-size: 170px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 55.25px;
  @media only screen and (min-width: 2269px) and (max-width: 2868px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1869px) and (max-width: 2268px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1537px) and (max-width: 1868px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 140px;
    letter-spacing: 25.4px;
    width: 80%;
    margin-top: -6%;
    transform:translateY(81px);
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 112px;
    letter-spacing: 36.4px;
    width: 80%;
    
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 90px;
    letter-spacing: 29.25px;
    width: 80%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 73px;
    letter-spacing: 23.25px;
    font-weight:900;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 59px;
    letter-spacing: 2.25px;
    margin-top: -10px;
    transform:translateY(-6px);
    font-weight:900;
  }
`;
export const StyledH2 = styled.h1`
  width: 90%;
  margin-top: 3%;
  color: #eef0f5;
  text-align: center;
  font-family: Poppins;
  font-size: 140px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 55.25px;
  @media only screen and (min-width: 2269px) and (max-width: 2868px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1869px) and (max-width: 2268px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1537px) and (max-width: 1868px) {
    font-size: 140px;
    letter-spacing: 45.4px;
    width: 80%;
    margin-top: -4%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 140px;
    letter-spacing: 25.4px;
    width: 80%;
    margin-top: -6%;
    transform:translateY(81px);
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    font-size: 112px;
    letter-spacing: 36.4px;
    width: 80%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 90px;
    letter-spacing: 29.25px;
    width: 80%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 73px;
    letter-spacing: 23.25px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 59px;
    letter-spacing: 2.25px;
    margin-top: -10px;
    transform:translateY(-6px);
  }
`;
export const StyledImg = styled.img`
  width: 625px !important;
  height: 470px;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  bottom: 10%;
  flex-shrink: 0;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // border: none;
  @media only screen and (min-width: 1537px) and (max-width: 2800px) {
    width: 625px !important;
    height: 450px;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 625px !important;
    height: 450px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 700px !important;
    height: 410px;
    bottom: 8%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 600px !important;
    height: 340px;
    bottom: 6%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 500px !important;
    height: 300px;
    bottom: 6%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 380px !important;
    height: 240px;
    bottom: 5%;
  }
`;
export const StyledText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 80%;
  }
`;
export const StyledP = styled.p`
  color: rgba(22, 32, 93, 0.81);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.1px; /* 169.375% */
  letter-spacing: 0.8px;
  text-transform: uppercase;


  & span {
    font-weight: 700;
  }
  @media only screen and (min-width: 2269px) and (max-width: 2868px) {
    margin-top: -10%;
  }
  @media only screen and (min-width:1869px) and (max-width:2268px){
    margin-top: -10%;
  }
  @media only screen and (min-width:1537px) and (max-width:1868px){
    margin-top: -10%;
  }
  @media only screen and (min-width:1281px) and (max-width:1536px){
    margin-top: -14%;
  }
  @media only screen and (min-width:1281px) and (max-width:1536px){
    margin-top: -14%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
  }
`;
export const StyledLine = styled.div`
  width: 106px;
  height: 7px;
  flex-shrink: 0;
  margin-top: 3%;
`;
export const StyledCarousel = styled.div`
  width: 100%;
  flex: 0 0 10%;
  .control-dots .dot {
    background: rgba(22, 32, 93, 0.49);
    // border: 2px solid #007bff;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    viewbox: 0 0 21 21;
    fill: none;
    margin: 0 5px;
  }
  .control-dots .dot.selected {
    // background-color: #007bff;
    // border: 2px solid #007bff;
    stroke: #16205d;
    stroke-opacity: 0.49;
    cursor: pointer;
    stroke-width: 1px;
    background: rgba(22, 32, 93, 1);
  }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 17px 0;
    }
`;
