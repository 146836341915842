import React from "react";
import {
  Icon,
  Icon1,
  StyledA,
  StyledB,
  StyledButton,
  StyledContent,
  StyledDiv,
  StyledDiv1,
  StyledH1,
  StyledH2,
  StyledIcon,
  StyledIcon1,
  StyledIconRow,
  StyledInput,
  StyledMain,
  StyledMap,
  StyledMapM,
  StyledOption,
  StyledP,
  StyledP1,
  StyledP2,
  StyledSelect,
  StyledText,
} from "./styles";
import {
  FaMailBulk,
  FaMailchimp,
  FaPhoneAlt,
  FaPhoneSquare,
} from "react-icons/fa";

function ContactInformation() {
  return (
    <StyledMain>
      <StyledA>
        <StyledText>
          <StyledH2>
            {" "}
            <span>Con</span>tact Information
          </StyledH2>
          <StyledP>
            We're a team of experienced designers, developers, and marketers,
            passionate about delivering exceptional digital solutions.
          </StyledP>
          <StyledIconRow>
            <StyledIcon>
              <Icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50%"
                  height="50%"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M2.81556 6.05889C3.93556 8.26 5.74 10.0567 7.94111 11.1844L9.65222 9.47333C9.86222 9.26333 10.1733 9.19333 10.4456 9.28667C11.3167 9.57444 12.2578 9.73 13.2222 9.73C13.65 9.73 14 10.08 14 10.5078V13.2222C14 13.65 13.65 14 13.2222 14C5.91889 14 0 8.08111 0 0.777778C0 0.35 0.35 0 0.777778 0H3.5C3.92778 0 4.27778 0.35 4.27778 0.777778C4.27778 1.75 4.43333 2.68333 4.72111 3.55444C4.80667 3.82667 4.74444 4.13 4.52667 4.34778L2.81556 6.05889Z"
                    fill="black"
                  />
                </svg>
              </Icon>
              <StyledP1>888 491 5293</StyledP1>
            </StyledIcon>
            <StyledIcon1>
              <Icon1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60%"
                  height="60%"
                  viewBox="0 0 19 18"
                  fill="none"
                >
                  <path
                    d="M16.7778 5.91016V12.828C16.7779 13.3872 16.5642 13.9254 16.1805 14.3323C15.7968 14.7392 15.2721 14.9842 14.7138 15.017L14.5852 15.0206H4.35281C3.79353 15.0206 3.25537 14.807 2.84846 14.4233C2.44154 14.0396 2.19662 13.5149 2.16381 12.9566L2.16016 12.828V5.91016L9.06335 10.5125L9.14813 10.5608C9.24805 10.6096 9.35779 10.635 9.46899 10.635C9.5802 10.635 9.68993 10.6096 9.78985 10.5608L9.87463 10.5125L16.7778 5.91016Z"
                    fill="black"
                  />
                  <path
                    d="M14.5861 3.32422C15.3755 3.32422 16.0676 3.74082 16.4535 4.36719L9.46992 9.02292L2.48633 4.36719C2.66958 4.06955 2.92136 3.82005 3.22065 3.63951C3.51994 3.45897 3.85807 3.35262 4.20683 3.32933L4.35374 3.32422H14.5861Z"
                    fill="black"
                  />
                </svg>
              </Icon1>
              <StyledP1>sales@liamcrest.com</StyledP1>
            </StyledIcon1>
          </StyledIconRow>
          <StyledP2>
            Your details are kept strictly confidential as per our{" "}
            <span>Privacy</span>{" "}
          </StyledP2>
        </StyledText>
        <StyledB>
          <StyledContent>
            <StyledH1>
              {" "}
              <span>Req</span>uest a Call back
            </StyledH1>
            <StyledDiv>
              <StyledSelect>
                <StyledOption value={0}>Web Development</StyledOption>
                <StyledOption value={1}>Development</StyledOption>
              </StyledSelect>
              <StyledInput placeholder="Full Name"></StyledInput>

              {/* <StyledDiv1> */}
              <StyledInput placeholder="Phone Number"></StyledInput>
              <StyledButton>Submit</StyledButton>
              {/* </StyledDiv1> */}
            </StyledDiv>
          </StyledContent>
        </StyledB>
      </StyledA>
      <StyledMap>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13310.064500974384!2d-112.077679!3d33.487946500000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b1342dee7e28d%3A0x802dd0a5ae7de5b2!2sLiam%20Crest!5e0!3m2!1sen!2s!4v1696448117681!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </StyledMap>
      <StyledMapM src="/image32.png" alt="img"></StyledMapM>
    </StyledMain>
  );
}

export default ContactInformation;
