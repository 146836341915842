import React from "react";
import icon1 from "../assets/case1.png";
import icon2 from "../assets/case2.png";
import icon3 from "../assets/case3.png";
import icon4 from "../assets/case4.png";

const Service1 = () => <img src={icon1} alt="Mobile Icon" />;
const Service2 = () => <img src={icon2} alt="Mobile Icon" />;
const Service3 = () => <img src={icon3} alt="Data Analysis" />;
const Service4 = () => <img src={icon4} alt="Mobile Icon" />;

export const CardData = [
  {
    image1: require("../assets/card12.png"),
    title: "App Design",
    desc: "Where innovative ideas meet stunning app design for a seamless user experience.",
  },
  {
    image2: require("../assets/card13.png"),
    title: "Website Design",
    desc: "Where pixels meet purpose, we create websites that leave a lasting impression.",
  },
  {
    image3: require("../assets/card14.png"),
    title: "SEO & SMM",
    desc: "Transforming clicks into customers with SEO & SMM expertise.",
  },
  {
    image4: require("../assets/card15.png"),
    title: " Digital Marketing",
    desc: " Your brand's digital destiny, our marketing mastery.",
  },
  {
    image5: require("../assets/card16.png"),
    title: "E-Learning Management",
    desc: "Navigating the e-learning landscape for smarter, effective learning.",
  },
  {
    image6: require("../assets/card17.png"),
    title: "Learning Management System (LMS)",
    desc: "Innovative LMS solutions for seamless learning.",
  },
  {
    image7: require("../assets/card18.png"),
    title: "App Development",
    desc: "From concept to reality, we turn your app vision into a digital masterpiece.",
  },
  {
    image8: require("../assets/card19.png"),
    title: "Website Development",
    desc: "Your vision, our expertise – we build websites that make your business shine online.",
  },
];

export const ProjectDetail1 = [
  {
    ProjectImg: <Service1 />,
    ProjectNumber: "50+",
    ProjectDetail: "CLIENTS WORLDWIDE",
  },
  {
    ProjectImg: <Service2 />,
    ProjectNumber: "160+",
    ProjectDetail: "PROJECTS COMPLETED",
  },

  {
    ProjectImg: <Service3 />,
    ProjectNumber: "20+",
    ProjectDetail: "TEAM MEMBERS",
  },

  {
    ProjectImg: <Service4 />,
    ProjectNumber: "8+",
    ProjectDetail: "YEAR EXPERIENCE",
  },
];
