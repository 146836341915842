import React, { useState, useEffect, useRef } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import {
  ContainerHeader,
  ImageWrapper,
  Images,
  Image,
  TextOverlay,
  ArrowIcon,
  Arrow1,
  SliderWrapper,
  Dot,
  Button,
  Container1,
  But1
} from "./styles";

import img1 from "../../assets/img_1.png";
import img2 from "../../assets/img_2.png";
import img3 from "../../assets/img_3.png";
import img4 from "../../assets/img_4.png";
import img5 from "../../assets/img_8.png";
import img6 from "../../assets/img_9.png";
import img7 from "../../assets/img_7.png";
import img8 from "../../assets/img_6.png";
import ContactFormPopup from "../ContactPopUp";

const images = [img1, img2, img3, img4];

const defaultImages = [img5, img6, img7, img8];
const AdditionalText = ({ children }) => (
  <div className="additional-text" style={{ display: "inline" }}>
    {children}
  </div>
);

const HeroSection = () => {
  const [hoveredImage, setHoveredImage] = useState("img1");
  const [activeIndex, setActiveIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showButton, setShowButton] = useState(
    window.innerWidth >= 320 && window.innerWidth <= 809
  );
  const [isFormOpen, setFormOpen] = useState(false);
  const [showMoreText, setShowMoreText] = useState(Array(images.length).fill(false));

  const toggleMoreText = (index) => {
    const newShowMoreText = [...showMoreText];
    newShowMoreText[index] = !newShowMoreText[index];
    setShowMoreText(newShowMoreText);
  };

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const handleHover = (imageId) => {
    const newHoveredImage = imageId || "img1";
    setHoveredImage(newHoveredImage);
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
    setHoveredImage(`img${index + 1}`);
  };

  const handleArrowClick = (index) => {
    toggleMoreText(index);
  };

  const preloadedImages = useRef([]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 809px)");

    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);

      if (mediaQuery.matches || newScreenWidth <= 809) {
        setShowButton(true);
        document.querySelectorAll(".hideable-image").forEach((el, index) => {
          el.style.display = index === activeIndex ? "block" : "none";
        });
      } else {
        setShowButton(false);
        document.querySelectorAll(".hideable-image").forEach((el) => {
          el.style.display = "block";
        });
      }
    };

    const handlePreload = async () => {
      const loadImage = async (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
        });
      };

      const preload = async () => {
        const promises = [...images, ...defaultImages].map((src) =>
          loadImage(src)
        );

        try {
          preloadedImages.current = await Promise.all(promises);
        } catch (error) {
          console.error("Error preloading images:", error);
        }
      };

      await preload();
      setHoveredImage((prev) => prev);
    };

    handleResize();
    handlePreload();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeIndex, preloadedImages]);
  return (
    <>
      <ContainerHeader id="Home">
        <Container1>
          <ImageWrapper>
            {images.map((src, index) => (
              <Images
                key={`img${index + 1}`}
                className={`hideable-image ${
                  index !== activeIndex ? "hidden" : ""
                }`}
              >
                <Image
                  src={
                    hoveredImage === `img${index + 1}`
                      ? src
                      : defaultImages[index]
                  }
                  alt={`Image ${index + 1}`}
                  onMouseEnter={() => handleHover(`img${index + 1}`)}
                  isHovered={hoveredImage === `img${index + 1}`}
                />
                {hoveredImage === `img${index + 1}` && (
                  <TextOverlay>
                    {index === 0 && "Accelerate your Software Development "}
                    {index === 1 && "Develop Powerful Apps for your Business "}
                    {index === 2 && "We Create Solutions for your Business "}
                    {index === 3 && "We Implement Corporate Software"}
                    {showMoreText[index] && (
                      <AdditionalText>
                        {index === 0 &&
                          "by embracing a culture of innovation with Liam Crest, whether it's software, consulting, or a combination of services."}
                        {index === 1 &&
                          "with custom-built web applications that can enhance your operations – indeed, powerful tools for improving business."}
                        {index === 2 &&
                          "goals in today's dynamic and competitive environment by understanding your client's challenges."}
                        {index === 3 &&
                          ", a critical service that can greatly enhance the efficiency and effectiveness of businesses' goals and objectives."}
                      </AdditionalText>
                    )}
                    <Arrow1 onClick={() => handleArrowClick(index)}>
                      {showButton && screenWidth <= 809 ? (
                        <But1>
                          {" "}
                          <Button>Get Started</Button>
                        </But1>
                      ) : (
                        <ArrowIcon
                          FontAwesomeIcon
                          icon={faArrowRight}
                          isHovered={hoveredImage === `img${index + 1}`}
                          onClick={() => handleArrowClick(index)}
                        />
                      )}
                    </Arrow1>
                  </TextOverlay>
                )}
              </Images>
            ))}
          </ImageWrapper>
          {images.length > 1 && screenWidth <= 809 && (
            <SliderWrapper>
              {images.map((_, index) => (
                <Dot
                  key={`dot${index}`}
                  isActive={index === activeIndex}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </SliderWrapper>
          )}
        </Container1>
      </ContainerHeader>
      {isFormOpen && <ContactFormPopup onClose={closeForm} />}
    </>
  );
};

export default HeroSection;
